import { url } from "./regex";

export const validUrl = (string: string) => {
  try {
    if (!Boolean(string)) return false;

    return url.test(string);
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const toValidPath = (string: string): string => {
  try {
    if (!Boolean(string)) return "";

    const parts = string.split("/").filter(Boolean);
    const cleanedPath = "/" + parts.join("/");
    return cleanedPath;
  } catch (err) {
    console.error(err);
    return "";
  }
};
