import { validDomain } from "../data/regex";
import { toValidPath } from "../data/url";

interface urlArgsType {
  url: string;
  newTab?: boolean;
}

export const openExternalUrl = (data: urlArgsType | string) => {
  try {
    const getUrl = (srcUrl: string) => {
      try {
        let path = srcUrl;
        if (!validDomain.test(path)) {
          const srcUrl = toValidPath(`/${path}`);
          path = `${window?.location?.origin}${srcUrl}`;
        }

        if (!/^https?:\/\//i.test(path)) {
          path = "http://" + path;
        }

        return path;
      } catch (error) {
        return srcUrl;
      }
    };
    if (typeof data === "string") {
      let url = getUrl(data);
      if (window) {
        window.open(url, "_self");
      }
    } else if (typeof data === "object") {
      let url = getUrl(data?.url);

      if (!/^https?:\/\//i.test(url)) {
        url = "http://" + url;
      }

      if (data?.newTab) {
        if (window) window.open(url, "_blank");
      } else {
        if (window) window.open(url, "_self");
      }
    }
  } catch (error) {
    console.error(error);
  }
};
