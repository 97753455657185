import styled from "styled-components";

export const BadgeContainer = styled.div<{ $isCount: boolean }>`
  position: absolute;
  top: ${({ $isCount }) => ($isCount ? "-10px" : ".5rem")};
  left: ${({ $isCount }) => ($isCount ? "10px" : "1.75rem")};
`;

export const BadgeWrapper = styled.div<{ $isCount: boolean }>`
  background-color: ${({ theme: { color } }) => color.main};
  border-radius: ${({ $isCount }) => ($isCount ? "50px" : "100px")};
  padding: ${({ $isCount }) => ($isCount ? ".1em 6px" : "4px")};
  width: ${({ $isCount }) => ($isCount ? "100%" : "1px")};
  height: ${({ $isCount }) => ($isCount ? "100%" : "1px")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextHolder = styled.span`
  line-height: 10px;
  margin-bottom: 0.1em;
  font-size: ${({ theme: { font } }) => font.sub};
  font-weight: bold;
`;
