import { useEffect } from "react";
// import * as inboxActions from "../../../../../../../../pages/user/common/inbox/@redux/actions";
// import * as inboxQueries from "../../../../../../../../pages/user/common/inbox/@graphql/queries";
import NotificationsItem from "./item";

const Notifications = ({ notifications }: { notifications: any }) => {
  // const { data } = useQuery(inboxQueries.GET_CONVERSATIONS, {
  //   fetchPolicy: "cache-and-network",
  // });

  // useEffect(() => {
  //   if (data) {
  //     const conversationData = inboxQueries?.dataResolver(
  //       data,
  //       "conversations"
  //     );
  //     dispatch(inboxActions.setConversations(conversationData));
  //   }
  // }, [data, dispatch]);

  return (
    <div className="p-5 w-72 max-w-72 max-h-80 overflow-auto">
      {Array.isArray(notifications) && notifications?.length > 0 ? (
        <>
          {notifications?.map((nt, index) => {
            const sameTicketId =
              index > 0 &&
              notifications[index]?.conversation?.order?.ticketId ===
                notifications[index - 1]?.conversation?.order?.ticketId;

            return (
              <NotificationsItem
                key={nt?._id * index || index}
                notification={nt}
                sameTicket={sameTicketId}
              />
            );
          })}
        </>
      ) : (
        <p>No New Messages Available</p>
      )}
    </div>
  );
};

export default Notifications;
