import styled from "styled-components";
import { headerHeight } from "../../styles/constants";
import { StyledType } from "./types";

export const Container = styled.header<StyledType>`
  position: fixed;
  top: 0;
  z-index: 112;

  width: 100%;
  max-width: 100vw;
  height: ${headerHeight};
  max-height: 41px;

  background-color: ${({ theme: { color } }) => color.sub};
  color: ${({ theme: { color } }) => color.white};

  user-select: none;
  display: ${({ $hide }) => ($hide ? "none" : "flex")};
  /* border-bottom: 1px solid ${({ theme: { color } }) => `${color.gray}10`}; */
`;

export const Wrapper = styled.div`
  width: 100%;
  ${({ theme: { placeholders } }) => placeholders.flexCenter};

  position: relative;
  max-height: 100%;
`;

export const LeftWrapper = styled.div`
  ${({ theme: { placeholders } }) => placeholders.flexCenter};
  justify-content: center;
  height: 100%;
  max-width: 230px;

  & > * {
    padding-inline: 1rem;
  }
`;

export const Branding = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-width: 40px;
  max-height: 30px;

  padding-left: 0;

  ${({ theme: { placeholders } }) => placeholders.flexCenter};
  justify-content: center;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -1px;
    width: 1px;
    height: 60%;
    bottom: 8px;
    display: block;
    background: ${({ theme: { color } }) => `${color.gray}50`};
  }
`;

export const RightWrapper = styled.div`
  ${({ theme: { placeholders } }) => placeholders.flexCenter};
  justify-content: flex-end;
  gap: 20px;
  height: 100%;
`;
