import { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { DefaultRoute, AppRoutes, PrivateRoutes } from "./routes";
import PrivateRoute from "./helper/private-route";
import Loader from "@/toolkit/ui/loader";
import { validArray } from "@/toolkit/utils/helpers/data/array";
import { isEmpty } from "@/toolkit/utils/helpers/data/object";

const AppRouter = () => {
  return (
    <>
      <Suspense fallback={<Loader big screen />}>
        <Routes>
          {validArray(AppRoutes) &&
            AppRoutes?.map((route, index) => {
              return (
                <Route
                  key={route.path + index}
                  path={route.path}
                  element={<route.component {...(route?.props ?? {})} />}
                />
              );
            })}

          {validArray(PrivateRoutes) &&
            PrivateRoutes?.map((route, index) => {
              if (isEmpty(route)) {
                return null;
              }

              return (
                <Route element={<PrivateRoute />} key={route.path + index}>
                  <Route
                    key={route.path + index}
                    path={route.path}
                    element={<route.component {...(route?.props ?? {})} />}
                  />
                </Route>
              );
            })}

          {!validArray(AppRoutes) && (
            <Route path={DefaultRoute} element={<Navigate to="/" />} />
          )}
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRouter;
