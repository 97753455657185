import UserAction from "./user";
// import { useSelector } from "react-redux";
// import * as Selector from "../../../../pages/auth/@redux/selectors";
import * as Styles from "./styles";

const Actions = () => {
  // const isAuthenticated = useSelector(Selector.selectIsAuthenticated);
  const isAuthenticated = true;

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Styles.Container>
      <UserAction />
    </Styles.Container>
  );
};

export default Actions;
