import { ChevronDown } from "react-feather";

const SelectBox = ({
  handleOpen,
  selected,
}: {
  handleOpen: () => void;
  selected: string;
}) => {
  return (
    <div
      role="button"
      title="Select Country Code"
      onClick={() => handleOpen()}
      className="relative cursor-pointer flex items-center h-full px-1.5 hover:bg-main hover:bg-opacity-10"
    >
      <span className="text-sm font-medium block text-font">{selected}</span>
      <ChevronDown size={10} />
    </div>
  );
};

export default SelectBox;
