import styled from "styled-components";

export const Container = styled.div`
  cursor: pointer;
  /* position: absolute;
  left: 0;
  transform: translateX(-200%); */

  svg {
    width: 20px;
    height: 20px;
    stroke-width: 2.5px;
  }
`;
