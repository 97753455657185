import styled from "styled-components";
import { StyledType } from "./types";

const tooltipMarginY = "45px";
const tooltipMarginX = "15px";
const tooltipArrowSize = "8px";

export const Container = styled.div`
  position: relative;
`;

export const TooltipHolder = styled.div<StyledType>`
  position: absolute;
  border-radius: ${({ theme: { element } }) => element.radius};
  left: 50%;
  transform: translateX(-50%);
  padding: ${({ $padding }) => ($padding ? $padding : "0.55rem")};
  color: ${({ theme: { color } }) => color.white};
  background: ${({ $bg, theme: { color } }) => ($bg ? $bg : color.main)};
  font-size: ${({ theme: { font } }) => font.small};
  line-height: 2.85em;
  font-weight: ${({ $bold }) => ($bold ? 600 : 400)};
  font-family: sans-serif;
  box-shadow: 0 0 10px ${({ theme: { color } }) => `${color.sub}80`};
  line-height: 1;
  z-index: 100;

  &::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: ${tooltipArrowSize};
    margin-left: calc(${tooltipArrowSize} * -1);
  }

  &.top {
    top: calc(${tooltipMarginY} * -1);

    &::before {
      top: 100%;
      border-top-color: ${({ $bg, theme: { color } }) =>
        $bg ? $bg : color.main};
    }
  }

  &.right {
    left: calc(
      100% + ${({ $sidebar }) => ($sidebar ? "22px" : tooltipMarginX)}
    );
    top: 50%;
    transform: translateX(0) translateY(-50%);

    &::before {
      left: calc(${tooltipArrowSize} * -1);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-right-color: ${({ $bg, theme: { color } }) =>
        $bg ? $bg : color.main};
    }
  }

  &.bottom {
    bottom: calc(${tooltipMarginY} * -1);

    &::before {
      bottom: 100%;
      border-bottom-color: ${({ $bg, theme: { color } }) =>
        $bg ? $bg : color.main};
    }
  }

  &.left {
    left: auto;
    right: calc(100% + ${tooltipMarginX});
    top: 50%;
    transform: translateX(0) translateY(-50%);

    &::before {
      left: auto;
      right: calc(${tooltipArrowSize} * -2);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-left-color: ${({ $bg, theme: { color } }) =>
        $bg ? $bg : color.main};
    }
  }
`;

export const TextHolder = styled.span<StyledType>`
  width: 100%;
  font-size: ${({ theme: { font } }) => font.small};
  font-weight: ${({ $bold }) => ($bold ? 600 : 400)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: ${({ $capitalize }) => ($capitalize ? "capitalize" : "none")};
`;
