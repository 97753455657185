import classNames from "classnames";
import {
  createElement,
  FC,
  Fragment,
  isValidElement,
  ReactNode,
  useRef,
  useState,
} from "react";
import { Sidebar, Star, XSquare } from "react-feather";
import Image from "../../ui/image";
import { validArray } from "../../utils/helpers/data/array";
import { specialChar } from "../../utils/helpers/data/regex";
import { openExternalUrl } from "../../utils/helpers/dom/navigate";
import { sidebarData } from "./data";
import {
  DashboardSidebarType,
  MenuDataEntryType,
  MenuDataType,
  MenuItemType,
} from "./types";

const DashboardSidebar: FC<DashboardSidebarType> = ({
  data,
  pathname,
  onNavigate,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const [dataShow, setDataShow] = useState<boolean>(false);
  const [makeSlide, setMakeSlide] = useState<boolean>(false);

  const handleSidebar = () => {
    setDataShow((prev) => !prev);
  };

  const handleNavigation = (
    path: string = "",
    externalUrl: boolean = false,
    openInNewTab: boolean = false
  ) => {
    try {
      if (Boolean(String(path))) {
        setMakeSlide(false);
        if (externalUrl) {
          openExternalUrl({ url: path, newTab: openInNewTab });
        } else {
          if (onNavigate) {
            onNavigate(path);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAllowed = (path: string, allowedPaths: string[]) => {
    try {
      return allowedPaths.some((allowedPath) => {
        if (allowedPath === "/*") return true;
        if (allowedPath === path) return true;
        if (
          allowedPath.endsWith("/*") &&
          path.startsWith(allowedPath.slice(0, -2))
        )
          return true;
        return false;
      });
    } catch (error) {
      console.error(error);
    }
  };

  const allData = data ?? sidebarData;

  const finalData = allData?.filter((item: MenuDataType) =>
    handleAllowed(pathname ?? "/*", item?.allow ?? [])
  );

  const renderIcon = (icon: any) => {
    try {
      const IconComponent = icon;
      return createElement(IconComponent, null);
    } catch (error) {
      return "";
    }
  };

  if (!validArray(finalData)) {
    return null;
  }

  return (
    <div
      ref={contentRef}
      aria-label="Dashboard Sidebar"
      className={classNames(
        "group relative shadow bg-sub text-white w-[60px] max-w-[235px] me-auto h-page z-[35] transition-[width] duration-150 ease-in-out [&::-webkit-scrollbar]:w-0 overflow-visible",
        "before:w-4 before:h-4 before:absolute before:top-0 before:right-[-1rem] before:rounded-tl-[50%] before:shadow-[rgb(48,48,48)_-9px_-1px_0px_0px] before:z-10 before:transition-[right] before:duration-150 before:ease-in-out",
        makeSlide && "hover:before:right-[-11.96rem]",
        dataShow && "!w-[235px] before:!right-[-1rem]"
      )}
      onMouseOver={() => setMakeSlide(true)}
      onMouseLeave={() => setMakeSlide(false)}
    >
      <nav
        className={classNames(
          "bg-sub w-[60px] absolute left-0 top-0 right-0 bottom-0 overflow-hidden z-50 transition-[width] duration-150 ease-in-out",
          makeSlide && "group-hover:w-[235px]",
          dataShow && "!w-[235px]"
        )}
      >
        <div className="flex flex-col gap-4 justify-between w-full h-full overflow-hidden ">
          <ul
            role="menu"
            className="flex-1 mt-1 bg-transparent w-full h-full list-none pl-0 leading-4 overflow-auto"
          >
            {finalData?.map((section: MenuDataType, index: number) => (
              <Fragment key={index}>
                {section?.data?.map(
                  (sectionItem: MenuDataEntryType, index: number) => {
                    return (
                      <li key={index} className="w-full mt-1">
                        {sectionItem?.title && (
                          <h6
                            className="px-4 my-2 text-xs text-ellipsis whitespace-nowrap overflow-hidden"
                            title={sectionItem?.title}
                          >
                            {sectionItem?.title}
                          </h6>
                        )}
                        {Array.isArray(sectionItem?.menu) &&
                          sectionItem?.menu?.length > 0 && (
                            <ul
                              role="menu"
                              className="flex-1 bg-transparent w-full h-full list-none pl-0 leading-4 overflow-visible"
                            >
                              {sectionItem?.menu?.map(
                                (menuItem: MenuItemType, menuIndex: number) => {
                                  const active =
                                    menuItem?.path &&
                                    pathname?.replace(specialChar, "") ===
                                      menuItem?.path.replace(specialChar, "");

                                  return (
                                    <li
                                      key={menuIndex}
                                      title={menuItem?.name}
                                      className={classNames(
                                        "menu_item px-1 mx-1 min-h-12"
                                      )}
                                      onClick={() =>
                                        handleNavigation(
                                          menuItem?.path,
                                          menuItem?.externalUrl,
                                          menuItem?.openInNewTab
                                        )
                                      }
                                    >
                                      <button
                                        className={classNames(
                                          "border-0 outline-none w-full flex flex-row gap-3 items-center justify-start rounded-base px-3 py-2.5 hover:bg-zinc-700",
                                          active && "bg-zinc-700"
                                        )}
                                      >
                                        {menuItem?.icon ? (
                                          <>
                                            {typeof menuItem?.icon ===
                                            "string" ? (
                                              <Image
                                                className={classNames(
                                                  "item_icon"
                                                )}
                                                src={menuItem?.icon}
                                                alt={menuItem?.name ?? "icon"}
                                                onError={(e) => {
                                                  const imgElement =
                                                    e.target as HTMLImageElement;
                                                  imgElement.src = "";
                                                }}
                                              />
                                            ) : isValidElement(
                                                menuItem?.icon
                                              ) ? (
                                              <>{menuItem?.icon}</>
                                            ) : (
                                              typeof menuItem?.icon ===
                                                "object" && (
                                                <span className="item_icon w-fit flex items-center justify-center [&>svg]:w-5 [&>svg]:h-5 [&>svg]:stroke-1.5">
                                                  <>
                                                    {renderIcon(
                                                      menuItem?.icon as ReactNode
                                                    )}
                                                  </>
                                                </span>
                                              )
                                            )}
                                          </>
                                        ) : (
                                          <span className="item_icon w-fit flex items-center justify-center [&>svg]:w-5 [&>svg]:h-5 [&>svg]:stroke-1.5">
                                            <Star />
                                          </span>
                                        )}

                                        <p
                                          title={menuItem?.name}
                                          className={classNames(
                                            "text-[0.9rem] invisible font-[450] text-left leading-normal text-ellipsis whitespace-nowrap w-max overflow-hidden",
                                            makeSlide && "group-hover:visible",
                                            dataShow && "!visible"
                                          )}
                                        >
                                          {menuItem?.name}
                                        </p>
                                      </button>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          )}
                      </li>
                    );
                  }
                )}
              </Fragment>
            ))}
          </ul>
          <button
            className={classNames(
              "border-0 h-8 mx-2 px-1 flex items-center justify-end invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-[opacity] duration-100 delay-100",
              dataShow && "!visible opacity-100"
            )}
            onClick={handleSidebar}
            title="Close"
          >
            <span className="bg-main text-white h-full px-2 w-fit flex items-center justify-center [&>svg]:w-5 [&>svg]:h-5 [&>svg]:stroke-1">
              {dataShow ? <XSquare /> : <Sidebar />}
            </span>
          </button>
        </div>
      </nav>
    </div>
  );
};

export default DashboardSidebar;
