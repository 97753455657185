import useOutsideAlert from "../../utils/hooks/useOutsideAlert";
import usePosition from "../../utils/hooks/usePosition";
import classNames from "classnames";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import useDropdown from "./@context";
import DropdownProvider from "./@context/provider";
import { DropdownReferenceType, DropdownType } from "./types";

const DropdownComp = forwardRef<DropdownReferenceType, DropdownType>(
  (
    {
      children,
      content,
      dropdownStyle,
      inlineAlign,
      topAlign,
      hover,
      className,
      contentClassName,
      actionClassName,
      onToggle,
      ...rest
    },
    ref
  ) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const actionRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef(null);

    const {
      open,
      setDropdownOpen,
      handleClose,
      handleDropdown,
      toggleDropdown,
    } = useDropdown();

    const { toLeft, toTop } = usePosition(actionRef);

    const isAligned = inlineAlign || topAlign;

    useEffect(() => {
      if (hover) {
        setDropdownOpen(true);
      }
    }, [hover, setDropdownOpen]);

    useImperativeHandle(ref, () => ({
      toggle: (v: boolean) => toggleDropdown(v),
    }));

    useOutsideAlert(wrapperRef, handleClose);

    return (
      <div
        ref={wrapperRef}
        className={classNames("dropdown relative group", className)}
      >
        <div
          ref={actionRef}
          role="button"
          className={classNames(
            "dropdown-action cursor-pointer",
            actionClassName
          )}
          onClick={(e) => handleDropdown(e)}
        >
          {children}
        </div>
        {open ? (
          <div
            ref={dropdownRef}
            className={classNames(
              "dropdown-content rounded-base bg-transparent absolute w-auto h-auto overflow-visible mt-0.5 z-[9]",
              hover &&
                "hidden group-hover:block group-hover:bg-white group-hover:bg-opacity-10",
              !isAligned && (toLeft ? "right-0" : "left-0"),
              !isAligned && (toTop ? "bottom-full" : "top-full"),
              inlineAlign === "left" ? "right-0" : "left-0",
              topAlign ? "bottom-full" : "top-full",
              contentClassName
            )}
            style={dropdownStyle}
            {...rest}
          >
            {content}
          </div>
        ) : null}
      </div>
    );
  }
);

const Dropdown = forwardRef<DropdownReferenceType, DropdownType>(
  ({ ...rest }, ref) => {
    return (
      <DropdownProvider {...rest}>
        <DropdownComp {...rest} ref={ref} />
      </DropdownProvider>
    );
  }
);

export { useDropdown };
export default Dropdown;
