export const capitalizeNames = (inputString: string) => {
  try {
    const words = inputString.split(" ");
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.slice(1);
      }
      return word;
    });

    const capitalizedString = capitalizedWords.join(" ");
    return capitalizedString;
  } catch (err) {
    console.error(err);
  }
};

export const hasCamelCase = (val: string) => {
  try {
    return /[a-z][A-Z]/.test(val);
  } catch (err) {
    console.error(err);
  }
};
