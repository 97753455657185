import classNames from "classnames";
import { FC, InputHTMLAttributes, ReactNode } from "react";
import { AlertOctagon } from "react-feather";

interface PropsType extends InputHTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
}

const InputError: FC<PropsType> = ({ children, className, ...rest }) => {
  return (
    <div
      id="input_error"
      className={classNames(
        "text-red text-sm my-2 flex items-center justify-start gap-x-1",
        className
      )}
      {...rest}
    >
      <AlertOctagon className="w-3 h-3" />
      {children}
    </div>
  );
};

export default InputError;
