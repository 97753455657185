import { Home } from "react-feather";

export const sidebarData = [
  {
    allow: ["/*"],
    data: [
      {
        title: "",
        menu: [
          {
            name: "dashboard",
            path: "/",
            icon: Home,
          },
        ],
      },
    ],
  },
];
