import styled from "styled-components";

export const Container = styled.div`
  ${({ theme: { placeholders } }) => placeholders.flexCenter};
  column-gap: 0;
`;

export const ItemWrapper = styled.div`
  position: relative;

  padding: 0.8rem 1rem;
  cursor: pointer;

  svg {
    stroke-width: 3;
  }

  &:hover {
    background-color: ${({ theme: { color } }) => `${color.blue}10`};

    svg {
      color: ${({ theme: { color } }) => color.orange};
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -1px;
    width: 1px;
    height: 60%;
    bottom: 8px;
    display: block;
    background: ${({ theme: { color } }) => `${color.gray}50`};
  }
`;
