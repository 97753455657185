import { lazy } from "react";
import { RouteType } from "./types";

const PrivateRoutes: RouteType[] = [
  {
    id: "dashboard",
    name: "dashboard",
    path: "",
    component: lazy(() => import("@/directory/pages/dashboard")),
    type: "private",
    props: {},
  },
  {
    id: "installation",
    name: "Installation",
    path: "/installation",
    component: lazy(() => import("@/directory/pages/installation")),
    type: "open",
    props: {},
  },
  {
    id: "ui",
    name: "UI",
    path: "/ui/*",
    component: lazy(() => import("@/directory/pages/ui")),
    type: "open",
    props: {},
  },
  {
    id: "ui_path",
    name: "UI",
    path: "/ui/:type",
    component: lazy(() => import("@/directory/pages/ui")),
    type: "open",
    props: {},
  },
  {
    id: "utils",
    name: "Utils",
    path: "/utils/*",
    component: lazy(() => import("@/directory/pages/utils")),
    type: "open",
    props: {},
  },
  {
    id: "utils_path",
    name: "Utils",
    path: "/utils/:type",
    component: lazy(() => import("@/directory/pages/utils")),
    type: "open",
    props: {},
  },
  {
    id: "layouts",
    name: "Layouts",
    path: "/layouts",
    component: lazy(() => import("@/directory/pages/layouts")),
    type: "open",
    props: {},
  },
  {
    id: "styles",
    name: "Styles",
    path: "/styles",
    component: lazy(() => import("@/directory/pages/styles")),
    type: "open",
    props: {},
  },
  {
    id: "common",
    name: "Common",
    path: "/common",
    component: lazy(() => import("@/directory/pages/common")),
    type: "open",
    props: {},
  },
  {
    id: "assets",
    name: "Assets",
    path: "/assets/*",
    component: lazy(() => import("@/directory/pages/assets")),
    type: "open",
    props: {},
  },
  {
    id: "assets",
    name: "Assets",
    path: "/assets/:type",
    component: lazy(() => import("@/directory/pages/assets")),
    type: "open",
    props: {},
  },
  {
    id: "all_toolkit",
    name: "all Toolkit",
    path: "/all",
    component: lazy(() => import("@/directory/pages/alltoolkit")),
    type: "open",
    props: {},
  },
];

export default PrivateRoutes;
