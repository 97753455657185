import { RefObject, useCallback, useEffect, useState } from "react";

const usePosition = (ref?: RefObject<HTMLElement>) => {
  const [position, setPositions] = useState<any>({});
  const [leftPosition, setLeftPosition] = useState<boolean>(false);
  const [topPosition, setTopPosition] = useState<boolean>(true);

  const calculatePosition = useCallback(
    (element: HTMLElement) => {
      try {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const rect = element.getBoundingClientRect();
        const leftSpace = rect.left;
        const rightSpace = viewportWidth - rect.right;
        const isLeft = leftSpace > rightSpace;
        setLeftPosition(isLeft);

        const topSpace = rect.top;
        const bottomSpace = viewportHeight - rect.bottom;
        const rectValues = {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
          top: rect.top,
          right: rect.right,
          bottom: bottomSpace ?? rect.bottom,
          left: rect.left,
        };
        const isTop = topSpace > bottomSpace;
        setPositions(rectValues);
        setTopPosition(isTop);
      } catch (error) {
        console.error(error);
      }
    },
    [setLeftPosition]
  );

  useEffect(() => {
    try {
      if (ref) {
        const element = ref.current;
        if (element) {
          calculatePosition(element);

          window.addEventListener("resize", () => calculatePosition(element));
          window.addEventListener("scroll", () => calculatePosition(element));
          document.addEventListener("scroll", () => calculatePosition(element));
          element.addEventListener("click", () => calculatePosition(element));

          return () => {
            window.removeEventListener("resize", () =>
              calculatePosition(element)
            );
            window.removeEventListener("scroll", () =>
              calculatePosition(element)
            );
            document.removeEventListener("scroll", () =>
              calculatePosition(element)
            );
            element.removeEventListener("click", () =>
              calculatePosition(element)
            );
          };
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [calculatePosition, ref]);

  return { ...position, toLeft: leftPosition, toTop: topPosition };
};

export default usePosition;
