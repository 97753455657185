import * as Styles from "./styles";

const Badge = ({ count }: { count?: string | number }) => {
  return (
    <Styles.BadgeContainer $isCount={Boolean(count)}>
      <Styles.BadgeWrapper $isCount={Boolean(count)}>
        <Styles.TextHolder>{count}</Styles.TextHolder>
      </Styles.BadgeWrapper>
    </Styles.BadgeContainer>
  );
};

export default Badge;
