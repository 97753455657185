import { color } from "./variables";
import { font } from "./variables";
import { placeholders } from "./placeholders";
import { element } from "./variables";
import { media } from "./mediaQuery";

const GetTheme = () => {
  const theme = {
    color,
    font,
    placeholders,
    element,
    media,
  };

  return theme;
};

export default GetTheme;
