// import { store } from "../../../../../../../redux/store";
// import { logout } from "../../../../../../../pages/auth/@redux/actions";

interface menuType {
  name?: string;
  type?: string;
  link?: string;
  tip?: string | null;
  event?: () => void | null;
}

interface dataType {
  title: string | null;
  menu: menuType[];
}

export const partnerMenu: dataType[] = [
  {
    title: "my activity",
    menu: [
      {
        name: "Account",
        type: "link",
        link: "/partner/account",
        tip: null,
      },
      {
        name: "Viewed Properties",
        type: "link",
        link: "/",
        tip: null,
      },
      {
        name: "Shortlisted Properties",
        type: "link",
        link: "/",
        tip: null,
      },
      {
        name: "Searches",
        type: "link",
        link: "/",
        tip: null,
      },
    ],
  },
  {
    title: null,
    menu: [
      {
        name: "Recommendations",
        type: "link",
        link: "/",
        tip: null,
      },
      {
        name: "My Profile",
        type: "link",
        link: "/",
        tip: null,
      },
    ],
  },
  {
    title: null,
    menu: [
      {
        name: "Logout",
        type: "button",
        // event: () => store.dispatch(logout()),
        tip: null,
      },
    ],
  },
];
