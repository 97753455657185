import styled from "styled-components";
import { footerHeight } from "../../styles/constants";
import { StyledType } from "./types";

export const Container = styled.footer<StyledType>`
  position: fixed;
  bottom: 0;
  z-index: 112;

  height: ${footerHeight};
  max-height: 31px;

  width: 100%;
  background-color: ${({ theme: { color } }) => color.dark};
  color: ${({ theme: { color } }) => color.dark};
  display: ${({ $hide }) => ($hide ? "none" : "block")};
  padding: 0.39454rem 0;
`;
