import { Search } from "react-feather";
import InputField from "../../../ui/input/field";

import * as Styles from "./styles";
import { useState } from "react";
import SearchDropdown from "./dropdown";

const AllSearch = () => {
  const [value, setValue] = useState("");
  const [focused, setFocused] = useState(false);

  const handleChange = (e) => {
    try {
      setValue(e?.target?.value ?? "");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Styles.Container className="header-search">
      <Styles.Wrapper>
        <InputField
          type="text"
          name="search"
          placeholder="Search"
          onChange={(e) => handleChange(e)}
          value={value || ""}
          icon={<Search />}
          onFocus={setFocused}
        />
      </Styles.Wrapper>

      {value && focused && <SearchDropdown />}
    </Styles.Container>
  );
};

export default AllSearch;
