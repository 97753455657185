import Profile from "./profile";
import Icons from "./icons";
import PageDimensions from "../../../../styles/usePageDimensions";

import * as Styles from "./styles";

const UserAction = () => {
  const { pageWidth } = PageDimensions();

  return (
    <Styles.Container aria-label="Menu Area">
      <Styles.Wrapper>
        <Icons />
        {pageWidth?.mobile_up && <Profile hideImage />}
      </Styles.Wrapper>
    </Styles.Container>
  );
};

export default UserAction;
