export const screenConstant = {
  SmobileWidth: "332px",
  mobileWidth: "500px",
  midMobile: "768px",
  tabletWidth: "995px",
  desktopWidth: "1200px",
  LargeDesktopWidth: "1399px",
};

const {
  SmobileWidth,
  midMobile,
  mobileWidth,
  tabletWidth,
  desktopWidth,
  LargeDesktopWidth,
} = screenConstant;

export const media = {
  LDesktop: `@media (min-width: ${LargeDesktopWidth})`,
  LDesktop_down: `@media (max-width: calc(${LargeDesktopWidth} - 1px))`,
  desktop: `@media (min-width: ${desktopWidth}) and (max-width: calc(${LargeDesktopWidth} - 1px))`,
  desktop_up: `@media (min-width: ${desktopWidth})`,
  desktop_down: `@media (max-width: calc(${desktopWidth} - 1px))`,
  tablet: `@media (min-width: ${mobileWidth}) and (max-width: calc(${tabletWidth} - 1px))`,
  tablet_land: `@media (min-width: ${tabletWidth}) and (max-width: calc(${desktopWidth} - 1px))`,
  tablet_up: `@media (min-width: ${tabletWidth})`,
  tablet_down: `@media (max-width: calc(${tabletWidth} - 1px))`,
  mobile_land: `@media (max-width: ${midMobile})`,
  mobile: `@media (max-width: ${mobileWidth})`,
  sm_mobile_up: `@media (min-width: ${SmobileWidth})`,
  sm_mobile: `@media (max-width: ${SmobileWidth})`,
};

// const new  = {
//     LDesktop: `@media (min-width: ${LargeDesktopWidth}px)`,
//     desktop: `@media (min-width: ${desktopWidth}px) and (max-width: calc(${LargeDesktopWidth}px - 1px))`,
//     desktop_up: `@media (min-width: ${desktopWidth}px)`,
//     desktop_down: `@media (max-width: calc(${desktopWidth}px - 1px))`,
//     tablet: `@media (min-width: ${mobileWidth}px) and (max-width: calc(${tabletWidth}px - 1px))`,
//     tablet_land: `@media (min-width: ${tabletWidth}px) and (max-width: calc(${desktopWidth}px - 1px))`,
//     tablet_up: `@media (min-width: ${tabletWidth}px)`,
//     tablet_down: `@media (max-width: calc(${tabletWidth}px - 1px))`,
//     mobile_down: `@media (max-width: ${midMobile}px)`,
//     mobile: `@media (max-width: ${mobileWidth}px)`,
//     sm_mobile: `@media (max-width: ${SmobileWidth}px)`,
// }
