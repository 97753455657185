import classNames from "classnames";
import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  Ref,
  HTMLAttributes,
} from "react";
import Styles from "./styles.module.css";

export interface InputWrapperType extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
  focused?: boolean;
  error?: boolean;
  valid?: boolean;
  noBOrder?: boolean;
}

const InputWrapper: ForwardRefRenderFunction<
  HTMLDivElement,
  InputWrapperType
> = (
  { children, className, focused, error, valid, noBOrder, ...rest },
  ref: Ref<HTMLDivElement>
) => {
  return (
    <div
      ref={ref}
      area-label="Input Wrapper"
      className={classNames(
        "input_wrapper w-full",
        Styles.input_wrapper,
        error && "!border-red",
        valid && "!border-green",
        focused && "!border-skyBlue",
        noBOrder && "!border-transparent",
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export default forwardRef(InputWrapper);
