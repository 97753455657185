import styled from "styled-components";
import { headerHeight } from "../../../styles/constants";

export const Container = styled.div`
  flex: 1;
  position: relative;
  height: ${headerHeight};
`;

export const Wrapper = styled.div`
  ${({ theme: { placeholders } }) => placeholders.flex};
  max-width: 40vw;
  padding: 0.1rem;
  height: ${headerHeight};

  .input {
    .input_wrapper {
      background-color: ${({ theme: { color } }) => color.black};
      input {
        color: ${({ theme: { color } }) => color.white};
        font-weight: 500;
      }

      .input_icon {
        &.focused {
          svg {
            color: ${({ theme: { color } }) => color.white};
          }
        }
      }
    }
  }
`;
