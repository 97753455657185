import { createContext, MouseEvent, useContext } from "react";

export interface ContextValueType {
  open: boolean;
  handleDropdown: (e: MouseEvent<HTMLDivElement>) => void;
  handleClose: () => void;
  setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleDropdown: (v: boolean) => void;
}

export const Context = createContext<ContextValueType | undefined>(undefined);

const useDropdown = (): ContextValueType => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("useDropdown must be used within a DropdownProvider");
  }

  return context;
};

export default useDropdown;
