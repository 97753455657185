export const isArraysEqual = (arr1: any, arr2: any) => {
  try {
    if (
      !Array.isArray(arr1) ||
      !Array.isArray(arr2) ||
      arr1?.length !== arr2?.length
    ) {
      return false;
    }

    const sortedArr1 = arr1?.slice()?.sort();
    const sortedArr2 = arr2?.slice()?.sort();

    const strArr1 = JSON.stringify(sortedArr1);
    const strArr2 = JSON.stringify(sortedArr2);

    return strArr1 === strArr2;
  } catch (err) {
    console.error(err);
  }
};

export const removeDuplicate = (array: any) => {
  try {
    const unique = Array.from(new Set(array));
    return unique;
  } catch (err) {
    console.error(err);
  }
};

export const validArray = (array: any): array is any[] => {
  try {
    return Array.isArray(array) && array.length > 0;
  } catch (err) {
    console.error(err);
    return false;
  }
};
