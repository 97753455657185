import { Suspense, lazy } from "react";
import Loader from "../../ui/loader";
import AppContainer from "../app-container";
import { validArray } from "../../utils/helpers/data/array";
import * as Data from "./data";
import * as Styles from "./styles";

const CopyrightFooter = lazy(() => import("./copyright"));

const Footer = ({ pathname }: { pathname?: string }) => {
  const hideFooter = () => {
    try {
      if (validArray(Data?.hideOnPaths) && pathname) {
        return Data?.hideOnPaths?.includes(pathname);
      } else return false;
    } catch (err) {
      return false;
    }
  };

  if (hideFooter()) {
    return null;
  }

  return (
    <Styles.Container
      id="global-footer"
      $hide={hideFooter()}
      aria-label="Footer"
    >
      <AppContainer $fluid>
        <Suspense
          fallback={
            <Loader
              count={5}
              containerStyle={{ padding: "50px" }}
              variant="skeleton"
            />
          }
        >
          <CopyrightFooter />
        </Suspense>
      </AppContainer>
    </Styles.Container>
  );
};

export default Footer;
