import { screenConstant } from "../../../styles//mediaQuery";
import { useCallback, useEffect, useState } from "react";

const usePageDimensions = (): any => {
  const hasWindow = window && typeof window !== "undefined";

  const getDimensions = useCallback(() => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }, [hasWindow]);

  const [dimensions, setDimensions] = useState(getDimensions());

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setDimensions(getDimensions());
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow, getDimensions]);

  const { width, height } = dimensions as any;

  const headerHeight: any =
    document.getElementById("global-header")?.offsetHeight;
  const headerCutHeight = height - headerHeight;

  const {
    SmobileWidth,
    midMobile,
    mobileWidth,
    tabletWidth,
    desktopWidth,
    LargeDesktopWidth,
  } = screenConstant as any;

  const pageWidth = {
    LDesktop: width > LargeDesktopWidth.replace("px", ""),
    desktop:
      width > desktopWidth.replace("px", "") &&
      width < LargeDesktopWidth.replace("px", "") - 1,
    desktop_up: width > desktopWidth.replace("px", ""),
    desktop_down: width < desktopWidth.replace("px", "") - 1,
    tablet:
      width > mobileWidth.replace("px", "") &&
      width < tabletWidth.replace("px", "") - 1,
    tablet_land:
      width > tabletWidth.replace("px", "") &&
      width < desktopWidth.replace("px", "") - 1,
    tablet_up: width > tabletWidth.replace("px", ""),
    tablet_down: width < tabletWidth.replace("px", "") - 1,
    mobile_up: width > mobileWidth.replace("px", ""),
    mobile:
      width > mobileWidth.replace("px", "") &&
      width < midMobile.replace("px", ""),
    mobile_down: width < mobileWidth.replace("px", "") - 1,
    mobile_land:
      width > mobileWidth.replace("px", "") &&
      width < midMobile.replace("px", "") - 1,
    mobile_land_up: width > midMobile.replace("px", "") - 1,
    sm_mobile_up: width > SmobileWidth.replace("px", ""),
    sm_mobile: width < SmobileWidth.replace("px", ""),
  };

  return { width, height, pageWidth, headerCutHeight };
};

export default usePageDimensions;
