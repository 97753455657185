//TODO
// Service Select dropdown

const ServicesDropdown = () => {
  return (
    <div className="p-5 w-72 max-w-72 max-h-80 overflow-auto">
      ServicesDropdown
    </div>
  );
};

export default ServicesDropdown;
