import NotificationsItem from "./item";

const NotificationList = ({ notifications }: { notifications?: any }) => {
  return (
    <div className="p-5 w-72 max-w-72 max-h-80 overflow-auto">
      <span>No New Notification Available</span>
    </div>
  );
};

export default NotificationList;
