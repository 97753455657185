import { validArray } from "../../../../../../../../utils/helpers/data/array";
import moment from "moment";
import { File, Image, Mail } from "react-feather";
import * as Styles from "./styles";

const NotificationsItem = ({
  notification,
  sameTicket,
}: {
  notification: any;
  sameTicket: any;
}) => {
  const conversations: any[] = [];
  // const { inbox } = useNavigationPath();
  const gotoChatDetails = () => {
    const currentConversation =
      validArray(conversations) &&
      conversations?.find(
        (item) => item?._id === notification?.conversation?._id
      );
    // navigate(inbox);
  };

  return (
    <Styles.Container onClick={() => gotoChatDetails()} sameTicket={sameTicket}>
      {!sameTicket && (
        <Styles.TextHolder head>
          #{notification?.conversation?.order?.ticketId}
        </Styles.TextHolder>
      )}
      {notification?.type === "text" ? (
        <Styles.TextHolder>{notification?.content}</Styles.TextHolder>
      ) : notification?.type === "image" ? (
        <Styles.TextHolder>
          <Image />
        </Styles.TextHolder>
      ) : notification?.type === "file" ? (
        <Styles.TextHolder>
          <File />
        </Styles.TextHolder>
      ) : notification?.type === "email" ? (
        <Styles.TextHolder>
          <Mail />
        </Styles.TextHolder>
      ) : (
        <Styles.TextHolder>•••</Styles.TextHolder>
      )}
      <Styles.TimeHolder>
        {moment(notification?.createdAt).format("h:mm")}
      </Styles.TimeHolder>
    </Styles.Container>
  );
};

export default NotificationsItem;
