import styled from "styled-components";
import { headerHeight } from "../../../../../../styles/constants";
import { ItemWrapper } from "../styles";

export const Container = styled.div`
  position: relative;
  height: ${headerHeight};
`;

export const Wrapper = styled(ItemWrapper)`
  height: ${headerHeight};
  display: flex;
  align-items: center;
  column-gap: 0.35rem;
  padding-top: 0.71rem;
  padding-bottom: 0.71rem;

  svg {
    stroke-width: 3;
  }

  &:hover {
    color: ${({ theme: { color } }) => color.orange};
  }
`;

export const TextHolder = styled.span`
  font-size: ${({ theme: { font } }) => font.small};
  font-weight: 600;
`;
