export const isObjectsEqual = (obj1: any, obj2: any) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
      if (!isObjectsEqual(obj1[key], obj2[key])) {
        return false;
      }
    } else if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const compareObjects = (obj1: any, obj2: any) => {
  // IT will only compare the keys available in obj2

  try {
    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    for (const key of keys2) {
      if (!keys1.includes(key)) {
        return false;
      }

      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  } catch (err) {
    console.error(err);
  }
};

export const isEmpty = (obj: any) => {
  try {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  } catch (err) {
    console.error(err);
  }
};

export const validFields = (
  obj: { [key: string]: any } | null,
  required: string[]
) => {
  try {
    if (!Boolean(obj) || !Boolean(required)) {
      return false;
    }

    return required.every(
      (field) => obj?.hasOwnProperty(field) && Boolean(obj[field])
    );
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const invalidFields = (
  obj: { [key: string]: any } | null,
  required: string[],
  message?: string
) => {
  try {
    if (!Boolean(required)) {
      return {};
    }

    if (!Boolean(obj)) {
      obj = {};
    }

    const fields = required.reduce(
      (emptyFieldsObj: { [key: string]: string }, field: string) => {
        if ((obj && !(field in obj)) || !Boolean(obj?.[field])) {
          emptyFieldsObj[field] = message ?? "Field Required";
        }
        return emptyFieldsObj;
      },
      {}
    );

    return fields;
  } catch (err) {
    console.error(err);
    return {};
  }
};
