import styled from "styled-components";
import { StyledType } from "../types";

export const Container = styled.div`
  padding: 10px 0;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
  overflow: hidden;
  ${({ theme: { placeholders } }) => placeholders.flexCenter};
`;

export const ImageHolder = styled.img<StyledType>`
  width: ${({ $size }) => ($size ? `${$size}px` : "42px")};
  height: ${({ $size }) => ($size ? `${$size}px` : "42px")};
  border: 2px solid
    ${({ active, theme: { color } }) => (active ? color.main : color.grey)};
  border-radius: 100px;

  object-fit: cover;
`;

export const InitialsWrapper = styled.div<StyledType>`
  width: ${({ $size }) => ($size ? `${$size}px` : "42px")};
  height: ${({ $size }) => ($size ? `${$size}px` : "42px")};
  border-radius: 100%;
  background: ${({ theme: { color } }) => color.grey};
  font-size: 100%;
  color: #fff;
  text-align: center;
  line-height: 42px;

  ${({ theme: { placeholders } }) => placeholders.flexCenter};
  justify-content: center;
`;
