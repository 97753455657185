import { ErrorBoundary } from "react-error-boundary";
import { ThemeProvider } from "styled-components";
import AppRouter from "./directory/router";
import GetTheme from "./toolkit/styles/theme";

function App() {
  const theme = GetTheme();
  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
