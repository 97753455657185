import classNames from "classnames";
import { FC } from "react";
import { LoaderType } from "../types";

interface VariantsType {
  center?: boolean;
  right?: boolean;
  small?: boolean;
  big?: boolean;
  box?: boolean;
  screen?: boolean;
  fullPage?: boolean;
  color?: string;
  dark?: boolean;
  light?: boolean;
}

const layoutVariants = ({
  fullPage,
  box,
  screen,
  center,
}: VariantsType): string => {
  return classNames("backdrop-blur-sm", {
    "relative block w-full h-full": !fullPage && !screen && !box,
    "bg-transparent fixed inset-0 bg-white/25 z-40 w-screen h-[calc(100vh - 100px)]":
      fullPage,
    "bg-transparent fixed inset-0 bg-white/25 w-screen h-screen z-[115]":
      screen,
    "bg-transparent absolute inset-0 bg-white/25 z-40": box,
    "flex items-center justify-center": fullPage || screen || center,
  });
};

const SpinLoader: FC<LoaderType> = ({
  small,
  big,
  box,
  screen,
  center,
  fullPage,
  color,
  dark,
  light,
  wrapperStyle,
  containerStyle,
  className,
}) => {
  return (
    <div
      aria-label="Loader"
      className={classNames(
        "loader group",
        layoutVariants({
          box,
          fullPage,
          screen,
        }),
        className
      )}
      style={containerStyle}
    >
      <div
        className={classNames(
          "loader group",
          layoutVariants({
            big,
            box,
            screen,
            center,
            fullPage,
            color,
            dark,
            light,
          })
        )}
        style={wrapperStyle}
      >
        <div
          className={classNames(
            big ? "w-14 h-14" : small ? "w-3.5 h-3.5" : "w-5 h-5",
            "inline-block border-[5px] border-solid border-[rgba(195,195,195,0.6)] rounded-full transition-opacity duration-1000 ease-in-out",
            "animate-spin [animation-duration:_1s] [animation-timing-function:_ease-in-out] [animation-iteration-count:_infinite]",
            {
              "border-t-main": !color && !dark && !light,
              "border-t-sub": dark,
              "border-t-white": light,
            }
          )}
          style={{ borderTopColor: color }}
        />
      </div>
    </div>
  );
};

export default SpinLoader;
