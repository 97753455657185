import { useState, useEffect } from "react";
import Dropdown from "../../../../../ui/dropdown";
import userPlaceholder from "../../../../../assets/placeholder/user.svg";
import DropdownContent from "./dropdown";
import ProfileIcon from "../../../../../ui/profile/icon";
import { ChevronDown } from "react-feather";
import { capitalizeNames } from "../../../../../utils/helpers/data/string";
import * as Styles from "./styles";

const Profile = ({ hideImage }: { hideImage: boolean }) => {
  const [avatar, setAvatar] = useState(userPlaceholder);

  const userTitle = "User";
  const finalAvatar = "";

  //TODO
  // decide correct user title to show

  useEffect(() => {
    setAvatar(userPlaceholder);
  }, [finalAvatar]);

  return (
    <Styles.Container>
      <Dropdown inlineAlign="left" content={<DropdownContent />}>
        <Styles.Wrapper>
          {!hideImage && <ProfileIcon avatar={avatar} />}
          <Styles.TextWrapper>
            <Styles.TextHolder $bold>
              {capitalizeNames(userTitle)}
            </Styles.TextHolder>
            {/* <Styles.TextHolder sub>{subTitle}</Styles.TextHolder> */}
          </Styles.TextWrapper>
          <ChevronDown size={16} />
        </Styles.Wrapper>
      </Dropdown>
    </Styles.Container>
  );
};

export default Profile;
