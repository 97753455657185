import classNames from "classnames";
import { FC } from "react";
import { LoaderType } from "../types";

interface VariantsType {
  center?: boolean;
  right?: boolean;
}

const layoutVariants = ({ center, right }: VariantsType): string => {
  return classNames(
    "w-full h-full flex flex-col items-start justify-start gap-2.5",
    {
      "items-center justify-center": center,
      "items-end justify-end": right,
    }
  );
};

const SkeletonBar = ({ height, width, style }: any) => {
  return (
    <span
      style={{ height, width, ...style }}
      className={classNames(
        "inline-block min-h-[1em] w-full relative overflow-hidden bg-[#dddbdd] rounded-md after:content-['']",
        "after:absolute after:top-0 after:right-0 after:bottom-0 after:left-0 after:transform after:-translate-x-full after:bg-gradient-to-r",
        "after:from-transparent after:via-[rgba(255,255,255,0.75)] after:to-transparent after:animate-shimmer after:[animation-duration:_2s] after:[animation-iteration-count:_infinite]"
      )}
    />
  );
};

const SkeletonLoader: FC<LoaderType> = ({
  count = 1,
  height,
  width,
  center,
  right,
  style,
  containerStyle,
  className,
}) => {
  return (
    <div
      aria-label="Skeleton-Loader"
      className={classNames(
        "skeleton_loader group",
        layoutVariants({ center, right }),
        className
      )}
      style={containerStyle}
    >
      {count ? (
        <>
          {[...Array(+count).keys()]?.map((_, index) => {
            return (
              <SkeletonBar
                key={index}
                width={width}
                height={height}
                style={style}
              />
            );
          })}
        </>
      ) : (
        <SkeletonBar width={width} height={height} style={style} />
      )}
    </div>
  );
};

export default SkeletonLoader;
