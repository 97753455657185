import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
`;

export const Wrapper = styled.div`
  ${({ theme: { placeholders } }) => placeholders.flexCenter};
  column-gap: 0.1rem;
`;
