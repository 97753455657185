import { FC } from "react";
import SkeletonLoader from "./skeleton";
import TableLoader from "./skeleton/table";
import SpinLoader from "./spin";
import { LoaderType } from "./types";

const Loader: FC<LoaderType> = ({ variant, ...rest }) => {
  return (
    <>
      {variant === "table" ? (
        <TableLoader {...rest} />
      ) : variant === "skeleton" ? (
        <SkeletonLoader {...rest} />
      ) : (
        <SpinLoader {...rest} />
      )}
    </>
  );
};

export default Loader;
