import { FC, MouseEvent, ReactNode, useState } from "react";

import { Context } from "./";

interface ContextTypes {
  children?: ReactNode;
  hover?: boolean;
  onToggle?: (data?: any) => void;
}

const DropdownProvider: FC<ContextTypes> = ({ children, hover, onToggle }) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const handleCallback = (val: boolean) => {
    try {
      if (onToggle) {
        onToggle(val);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleDropdown = (val: boolean) => {
    try {
      setDropdownOpen(val);

      if (hover) {
        setTimeout(() => {
          setDropdownOpen(true);
        }, 300);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDropdown = (e: MouseEvent<HTMLDivElement>) => {
    try {
      if (!hover) {
        e?.preventDefault();
        e?.stopPropagation();

        setDropdownOpen(!dropdownOpen);

        handleCallback(!dropdownOpen);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    try {
      setDropdownOpen(false);

      if (onToggle) {
        setTimeout(() => {
          handleCallback(false);
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const valueGroup = {
    open: dropdownOpen,
    handleDropdown,
    handleClose,
    setDropdownOpen,
    toggleDropdown,
  };

  return (
    <Context.Provider value={{ ...valueGroup }}>{children}</Context.Provider>
  );
};

export default DropdownProvider;
