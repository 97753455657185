import { countryCodes } from "../../common/locale/country-code";
import useOutsideAlert from "../../utils/hooks/useOutsideAlert";
import { FC, useRef, useState } from "react";
import { CountrySelectorType } from "./types";

import Dropdown from "./component/dropdown";
import SelectBox from "./component/select-box";

const CountrySelector: FC<CountrySelectorType> = ({
  onChange,
  value,
  name,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [selected, setSelected] = useState<string>(value || "+91");
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [resultCount, setResultCount] = useState<number>(5);

  const handleSelect = (val: string) => {
    try {
      const value = val?.toString();
      if (onChange) {
        onChange({
          target: {
            value: val,
            type: "dialCode",
            name: name || "countryCodes",
          },
        });
      }
      setSelected(value);
      setSearch("");
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  useOutsideAlert(containerRef, () => setOpen(false));

  const countryList = countryCodes.filter((item) => item.isoCode !== "IN");

  const filterResult = countryList.filter(
    (item) =>
      item.dialCode?.toLowerCase().includes(search?.toLowerCase()) ||
      item.name?.toLowerCase().includes(search?.toLowerCase()) ||
      item.isoCode?.toLowerCase().includes(search?.toLowerCase())
  );

  const finalList = (): any[] => {
    try {
      if (selected) {
        return countryList?.filter((item) => item?.dialCode !== selected);
      } else {
        return countryList;
      }
    } catch (error) {
      return [];
    }
  };

  const renderList = search ? filterResult : finalList();

  const PropsGroup = {
    containerRef,
    selected,
    open,
    search,
    setSearch,
    handleSelect,
    resultCount,
    setResultCount,
    renderList,
  };

  return (
    <div
      ref={containerRef}
      aria-label="Country Selector"
      className="relative select-none h-full flex items-center justify-center"
    >
      <SelectBox handleOpen={handleOpen} selected={selected} />

      <Dropdown {...PropsGroup} />
    </div>
  );
};

export default CountrySelector;
