import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  Observable,
  FetchResult,
  Operation,
} from "@apollo/client";
// import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
// import { setAccessToken } from "@/pages/auth/@redux/actions";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

type CustomOperation = {
  setContext: (context: Record<string, any>) => void;
};
type CustomResponse = FetchResult<
  Record<string, any>,
  Record<string, any>,
  Record<string, any>
>;

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_BASE_URL}/client/graphql`,
  credentials: "include",
});

// const uploadLink = createUploadLink({
//   uri: `${process.env.REACT_APP_API_BASE_URL}/client/graphql`,
// });

const authLink = setContext(async (_, { headers }) => {
  try {
    const accessToken = localStorage.getItem("user_access_token");
    return {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : "",
        ...headers,
      },
    };
  } catch (err) {
    console.error(err);
    return {
      headers,
    };
  }
});

const refreshTokenAndProceed = async (operation: any, forward: any) => {
  try {
    return new Promise((resolve, reject) => {
      // API.refreshToken()
      //   .then((res: any) => {
      //     const newAccessToken = res?.accessToken;
      //     // TODO
      //     // correct the AUTH & refresh functions
      //     if (newAccessToken) {
      //       localStorage.setItem("user_access_token", newAccessToken);
      //       operation.setContext(({ headers = {} }) => ({
      //         headers: {
      //           ...headers,
      //           Authorization: newAccessToken ? `Bearer ${newAccessToken}` : "",
      //         },
      //       }));
      //       const observable = forward(operation);
      //       observable.subscribe({
      //         next: resolve,
      //         error: reject,
      //       });
      //     } else {
      //       reject(new Error("Failed to get new access token"));
      //     }
      //   })
      //   .catch(reject);
    });
  } catch (err) {
    console.error(err);
  }
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    try {
      if (
        graphQLErrors &&
        Array.isArray(graphQLErrors) &&
        graphQLErrors?.some((err) => err?.statusCode === 403)
      ) {
        return new Observable((observer) => {
          (async () => {
            try {
              const result: any = await refreshTokenAndProceed(
                operation,
                forward
              );
              observer.next(result);
              observer.complete();
            } catch (error) {
              observer.error(error);
            }
          })();
        });
      } else if (networkError) {
        console.error(networkError?.message);
      }

      console.error("Failed to load the request");
    } catch (err) {
      console.error(err);
    }
  }
);

const requestHandlerLink = new ApolloLink((operation, forward) => {
  try {
    return forward(operation) || null;
  } catch (err) {
    console.error(err);
    return null;
  }
});

const responseMiddleware = new ApolloLink(
  (operation: Operation & CustomOperation, forward) => {
    try {
      return forward(operation).map((response: CustomResponse) => {
        if (response.errors) {
          console.error("GraphQL errors:", response?.errors);
        }
        return response;
      });
    } catch (error) {
      console.error(error);
      return null;
    }
  }
);

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    requestHandlerLink,
    responseMiddleware,
    authLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy(currentFetchPolicy) {
        if (
          currentFetchPolicy === "network-only" ||
          currentFetchPolicy === "cache-and-network"
        ) {
          return "cache-first";
        }
        return currentFetchPolicy;
      },
    },
  },
});

// apolloClient.setLink(uploadLink);

export default apolloClient;
