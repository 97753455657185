import { RefObject, useEffect } from "react";

const useOutsideAlert = (
  ref?: RefObject<HTMLElement>,
  callback?: (data: any, v?: boolean) => any,
  deactivate?: boolean
) => {
  useEffect(() => {
    if (!deactivate) {
      const handleClickOutside = (event: any) => {
        try {
          event?.stopPropagation();

          if (ref && ref.current && !ref.current.contains(event?.target)) {
            if (callback) {
              callback(event, false);
            }
          }
        } catch (error) {
          console.error(error);
        }
      };

      try {
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("beforeunload", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
          document.removeEventListener("beforeunload", handleClickOutside);
        };
      } catch (error) {
        console.error({ error });
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
          document.removeEventListener("beforeunload", handleClickOutside);
        };
      }
    }
  }, [ref, callback, deactivate]);
};

export default useOutsideAlert;
