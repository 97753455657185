import styled from "styled-components";

export const Container = styled.div<{ sameTicket?: boolean }>`
  border: 1px solid ${({ theme: { color } }) => color.grey};
  border-radius: ${({ theme: { element } }) => element.radius};
  padding: 10px;

  margin-top: ${({ sameTicket }) => (sameTicket ? "5px" : "20px")};

  position: relative;
  overflow: hidden;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const TextHolder = styled.p<{ head?: boolean; bold?: boolean }>`
  color: ${({ head, theme: { color } }) => (head ? color.main : color.font)};
  font-weight: ${({ bold }) => (bold ? 600 : 400)};

  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const TimeHolder = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${({ theme: { color } }) => color.white};
  box-shadow: 0 -15px 10px ${({ theme: { color } }) => `
$ {
  color.white
}
90 `};
  font-size: ${({ theme: { font } }) => font.small};
  padding: 5px 10px;
`;
