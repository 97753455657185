import classNames from "classnames";
import { FC, ReactNode, InputHTMLAttributes } from "react";

// const Container = styled.div`
//   width: 100%;
//   padding-right: 1rem;
//   padding-left: 1rem;
//   margin-right: auto;
//   margin-left: auto;

//   ${({ $fluid }: any) =>
//     !$fluid &&
//     `
//     @media (min-width: 576px) {
//       max-width: 540px;
//     }

//     @media (min-width: 768px) {
//       max-width: 720px;
//     }

//     @media (min-width: 992px) {
//       max-width: 960px;
//     }

//     @media (min-width: 1200px) {
//       max-width: 1140px;
//     }

//     @media (min-width: 1400px) {
//       max-width: 1320px;
//     }
//     `}
// `;

interface propsType {
  children?: ReactNode;
  $fluid?: boolean;
}

const AppContainer: FC<propsType & InputHTMLAttributes<HTMLInputElement>> = ({
  children,
  $fluid,
  ...otherProps
}) => {
  return (
    <div
      aria-label="App Container"
      className={classNames(
        "w-full px-4 mx-auto",
        !$fluid &&
          "sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl"
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export default AppContainer;
