import classNames from "classnames";
import { forwardRef, ForwardRefRenderFunction, Ref, useState } from "react";
import CountrySelector from "../../country-selector";
import InputError from "../components/error";
import InputIcon from "../components/icon";
import InputBox from "../components/input";
import InputLabel from "../components/label";
import InputWrapper from "../components/wrapper";
import { InputFieldTypes } from "./types";

const InputField: ForwardRefRenderFunction<
  HTMLInputElement,
  InputFieldTypes
> = (props, ref: Ref<HTMLInputElement>) => {
  const [passShow, setPassShow] = useState(false);
  const [focused, setFocused] = useState(false);

  const {
    label,
    attributes,
    passwordViewToggle,
    countrySelector,
    error,
    onFocus,
    onBlur,
    icon,
    type,
    value,
    className,
    ...rest
  } = props;

  const PassToggle = () => {
    setPassShow(!passShow);
  };

  const getFocused = () => {
    if (onFocus) {
      onFocus(true);
    }
    setFocused(true);
  };

  const getBlurred = () => {
    if (onBlur) {
      onBlur(false);
    }
    if (onFocus) {
      onFocus(false);
    }
    setFocused(false);
  };

  const newType = type === "password" && passShow ? "text" : type;

  return (
    <div
      aria-label="Input Field"
      className={classNames("input relative w-full my-1", className)}
    >
      {Boolean(label) && !Boolean(label?.dynamic) && (
        <InputLabel {...(label ?? {})} focused={focused} />
      )}

      <InputWrapper
        error={Boolean(error)}
        valid={value && !error}
        focused={focused}
      >
        {attributes?.prefix?.icon && (
          <InputIcon
            type={type}
            focused={focused}
            icon={attributes?.prefix?.icon}
            prefix
          />
        )}

        {countrySelector && (
          <div className="h-full">
            <CountrySelector {...countrySelector} />
          </div>
        )}

        <div className="relative w-full h-full">
          {Boolean(label?.dynamic) && (
            <InputLabel {...(label ?? {})} focused={focused} />
          )}
          <InputBox
            ref={ref}
            onFocus={() => getFocused()}
            onBlur={() => getBlurred()}
            value={value}
            type={newType}
            {...rest}
          />
        </div>

        {attributes?.suffix?.icon && (
          <InputIcon
            type={type}
            focused={focused}
            passShow={passShow}
            passwordViewToggle={passwordViewToggle}
            value={Boolean(value)}
            icon={{
              ...attributes?.suffix?.icon,
              onClick: () => {
                try {
                  if (type === "password") {
                    PassToggle();
                    if (attributes?.suffix?.icon?.onClick) {
                      attributes?.suffix?.icon?.onClick(!passShow);
                    }
                  } else if (attributes?.suffix?.icon?.onClick) {
                    attributes?.suffix?.icon?.onClick();
                  }
                } catch (error) {
                  console.error(error);
                }
              },
            }}
            suffix
          />
        )}
      </InputWrapper>

      {error && <InputError>{error}</InputError>}
    </div>
  );
};

export default forwardRef(InputField);
