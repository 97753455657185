import React from "react";

import PageDimensions from "../../../styles/usePageDimensions";
import { Menu, X } from "react-feather";
import * as Styles from "./styles";

const BurgerIcon = () => {
  const sideBar = false;

  const { pageWidth } = PageDimensions();

  const handleToggle = () => {
    // dispatch(Actions.sidebarToggle());
  };

  if (pageWidth.desktop_up) {
    return null;
  }

  return (
    <Styles.Container onClick={() => handleToggle()}>
      {sideBar ? <X /> : <Menu />}
    </Styles.Container>
  );
};

export default BurgerIcon;
