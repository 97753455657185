import { FC, SyntheticEvent } from "react";
import userPlaceholder from "../../../assets/placeholder/user.svg";
import * as Styles from "./styles";
import { propsType } from "../types";

const ProfileIcon: FC<propsType> = ({ avatar, name, size }) => {
  const finalName = name ?? "";

  const handleImageError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    try {
      const imgElement = e.target as HTMLImageElement;
      imgElement.src = userPlaceholder;
    } catch (err) {
      console.error(err);
    }
  };

  const getInitials = () => {
    try {
      if (Boolean(finalName)) {
        if (finalName.includes(" ")) {
          const words = finalName.split(" ");
          const initials = words
            .map((word) => word.charAt(0).toUpperCase())
            .join("");
          return initials;
        } else if (/^[a-z]+[A-Z]/.test(finalName)) {
          const camelCase = finalName
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
            .toUpperCase();
          const words = camelCase.split(" ");
          const initials = words
            .map((word) => word.charAt(0).toUpperCase())
            .join("");
          return initials;
        } else {
          return name?.charAt(0).toUpperCase();
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  if (!avatar && !name) {
    return null;
  }

  return (
    <Styles.Container>
      {Boolean(avatar) ? (
        <Styles.ImageWrapper>
          <Styles.ImageHolder
            src={avatar || ""}
            alt="User Avatar"
            onError={handleImageError}
            $size={size}
          />
        </Styles.ImageWrapper>
      ) : (
        Boolean(name) && (
          <Styles.InitialsWrapper $size={size}>
            {getInitials()}
          </Styles.InitialsWrapper>
        )
      )}
    </Styles.Container>
  );
};

export default ProfileIcon;
