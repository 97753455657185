import { Dispatch, FC, SetStateAction } from "react";

interface PropsType {
  optionSearchValue: string;
  setOptionSearchValue: Dispatch<SetStateAction<string>>;
}

const OptionSearch: FC<PropsType> = ({
  optionSearchValue,
  setOptionSearchValue,
}) => {
  return (
    <input
      className="border outline-0 w-full p-1.5 text-sm border-b-slate-300 border-t-transparent border-x-transparent text-font"
      type="search"
      name="search"
      placeholder="Search..."
      onChange={(e) => setOptionSearchValue(e?.target?.value)}
      value={optionSearchValue ?? ""}
      autoComplete="off"
      autoFocus
    />
  );
};

export default OptionSearch;
