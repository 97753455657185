import classNames from "classnames";
import { FC } from "react";

interface PropsType {
  selected: boolean;
  onClick: () => void;
  option: {
    flag: string;
    name: string;
    dialCode: string;
  };
}

const OptionItem: FC<PropsType> = ({ option, onClick, selected }) => {
  return (
    <div
      role="button"
      className={classNames(
        "cursor-pointer text-sb px-3 py-2.5 my-0.5 bg-white text-font flex items-center gap-1.5 hover:bg-main hover:bg-opacity-10",
        selected && "!bg-main !bg-opacity-20"
      )}
      onClick={() => onClick()}
    >
      <div className="w-9 h-full bg-slate-100 p-0.5 me-1.5">
        <img
          src={option?.flag}
          alt={option.name ?? "country-icon"}
          className="w-full h-full object-contain"
        />
      </div>

      <span className="block text-sm font-medium text-font">
        {option.name} ({option.dialCode})
      </span>
    </div>
  );
};

export default OptionItem;
