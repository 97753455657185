export type ColorType = {
  [key: string]: string;
};

export const color: ColorType = {
  main: "#E27735",
  orange: "#EDA44C",
  blue: "#4978D2",
  skyBlue: "#65BBE9",
  sub: "#303030",
  dark: "#292929",
  red: "#ed4c63",
  green: "#58c6a2",
  yellow: "#eddc4c",
  black: "#000000",
  white: "#ffffff",
  gray: "#949494",
  grey: "#49576f",
  page: "#f1f1f4",
  font: "#1F1D1D",
};

export const colorHSL = {
  main: "hsl(22, 84%, 55%)", // #E27735
  orange: "hsl(33, 85%, 66%)", // #EDA44C
  blue: "hsl(217, 60%, 47%)", // #4978D2
  skyBlue: "hsl(199, 72%, 66%)", // #65BBE9
  sub: "hsl(213, 17%, 21%)", // #192541
  dark: "hsl(0, 0%, 16%)", // #292929
  red: "hsl(352, 80%, 63%)", // #ed4c63
  green: "hsl(154, 48%, 60%)", // #58c6a2
  yellow: "hsl(54, 90%, 63%)", // #eddc4c
  black: "hsl(0, 0%, 0%)", // #000000
  white: "hsl(0, 0%, 100%)", // #ffffff
  gray: "hsl(210, 11%, 58%)", // #949494
  grey: "hsl(220, 17%, 43%)", // #49576f
  page: "hsl(220, 6%, 96%)", // #f1f1f4
  font: "hsl(214, 45%, 19%)", // #1b2d4a
};

export const font = {
  main: "1rem",
  sub: ".875rem",
  small: ".75rem",
  extraSmall: "0.625rem",
  title: "1.125rem",
  big: "1.25rem",
};

export const element = {
  radius: "0.2rem",
  boxPadding: "1.20rem",
  pagePadding: "1.25rem 2.25rem 2rem",
};
