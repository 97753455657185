import Button from "../../../../../../ui/button";
import { partnerMenu } from "./data";

import * as Styles from "./styles";

const DropdownContent = ({ pathname }: { pathname?: string }) => {
  // const navigate = useNavigate();
  const menuData = partnerMenu;

  return (
    <Styles.Container className="user dropdown">
      {menuData ? (
        <>
          {menuData.map((item, index) => {
            return (
              <Styles.Section key={index}>
                <Styles.TitleWrapper>
                  {item.title ? (
                    <Styles.TitleHolder>{item.title}</Styles.TitleHolder>
                  ) : null}
                </Styles.TitleWrapper>
                {item.menu ? (
                  <Styles.MenuList>
                    {item.menu.map((menu, menuIndex) => {
                      const { name, type, link, tip, event } = menu;
                      const isActive = pathname === link;

                      const handleEvent = () => {
                        if (event) {
                          event();
                        }
                      };

                      return (
                        <Styles.MenuItem key={menuIndex} $active={isActive}>
                          {type === "link" ? (
                            <>
                              <a href={link ?? ""} className="action-holder">
                                <Styles.TextHolder $active={isActive}>
                                  {name}
                                </Styles.TextHolder>
                              </a>
                            </>
                          ) : type === "event" ? (
                            <>
                              <Styles.EventWrapper
                                className="action-holder"
                                onClick={() => handleEvent()}
                              >
                                <Styles.TextHolder $active={isActive}>
                                  {name}
                                </Styles.TextHolder>
                              </Styles.EventWrapper>
                            </>
                          ) : type === "button" ? (
                            <>
                              <Button
                                className="action-holder"
                                size="full"
                                onClick={() => handleEvent()}
                              >
                                {name}
                              </Button>
                            </>
                          ) : type === "tip" ? (
                            <Styles.TipWrapper>
                              <Styles.TipHolder>{tip}</Styles.TipHolder>
                              <Styles.TipLinkHolder href={link ?? ""}>
                                {name}
                              </Styles.TipLinkHolder>
                            </Styles.TipWrapper>
                          ) : null}
                        </Styles.MenuItem>
                      );
                    })}
                  </Styles.MenuList>
                ) : null}
              </Styles.Section>
            );
          })}
        </>
      ) : (
        <Styles.TextHolder style={{ margin: "20px" }}>
          NO Menu Available
        </Styles.TextHolder>
      )}
    </Styles.Container>
  );
};

export default DropdownContent;
