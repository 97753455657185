import { MessageSquare } from "react-feather";
import Dropdown from "../../../../../../ui/dropdown";
import Badge from "../badge";
import Notifications from "./notifications";
import * as Styles from "./styles";

const Message = () => {
  const notifications: any[] = [];

  return (
    <div className="relative" title="Message">
      <Dropdown
        inlineAlign="left"
        content={<Notifications notifications={notifications} />}
      >
        <div className="relative">
          <Styles.IconWrapper>
            {Array.isArray(notifications) && notifications?.length > 0 && (
              <Badge />
            )}

            <MessageSquare />
          </Styles.IconWrapper>
        </div>
      </Dropdown>
    </div>
  );
};

export default Message;
