import React from "react";
import Dropdown from "../../../../../../ui/dropdown";
import { Briefcase } from "react-feather";
import Badge from "../badge";
import BusinessDropdown from "./dropdown";

import * as Styles from "./styles";

const Business = () => {
  return (
    <div className="relative" title="Business">
      <Dropdown inlineAlign="left" content={<BusinessDropdown />}>
        <div className="relative">
          <Styles.IconWrapper>
            <Badge />
            <Briefcase />
          </Styles.IconWrapper>
        </div>
      </Dropdown>
    </div>
  );
};

export default Business;
