import {
  forwardRef,
  ForwardRefRenderFunction,
  Ref,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import Placeholder from "../../assets/placeholder/image.png";
import { propsType } from "./types";
import classNames from "classnames";
import Loader from "../loader";

const ImageRender: ForwardRefRenderFunction<HTMLImageElement, propsType> = (
  { className, src, alt, onError, onLoad },
  ref: Ref<HTMLImageElement>
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleError = useCallback(
    (e: SyntheticEvent<HTMLImageElement, Event>) => {
      try {
        if (onError) {
          onError(e);
        } else {
          const imgElement = e.target as HTMLImageElement;
          imgElement.src = Placeholder;
        }
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    },
    [onError]
  );

  useEffect(() => {
    try {
      const img = new Image();
      img.src = src ?? "";

      img.onload = () => {
        setIsLoading(false);

        if (onLoad) {
          onLoad(false);
        }
      };

      img.onerror = () => {
        setIsLoading(false);

        if (onLoad) {
          onLoad(false);
        }
      };

      return () => {
        img.onload = null;
        img.onerror = null;
      };
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, [handleError, onLoad, src]);

  if (isLoading) {
    return (
      <div className="h-full w-full flex ite justify-center">
        <Loader variant="skeleton" count={1} height={"100%"} center />
      </div>
    );
  }

  return (
    <img
      ref={ref}
      className={classNames("image", className)}
      src={src ?? ""}
      alt={alt ?? "figure"}
      onError={handleError}
    />
  );
};

export default forwardRef(ImageRender);
