export const isNextApp = () => {
  try {
    if (process.env.NEXT_PUBLIC_APP && process.env.NEXT_PUBLIC_APP === "true") {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const isReactApp = () => {
  try {
    if (
      process.env.REACT_APP_PUBLIC &&
      process.env.REACT_APP_PUBLIC === "true"
    ) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
