import usePosition from "../../../../utils/hooks/usePosition";
import classNames from "classnames";
import { Dispatch, FC, RefObject, SetStateAction } from "react";
import Options from "./options";
import OptionSearch from "./search";

interface PropsType {
  containerRef: RefObject<HTMLDivElement>;
  open: boolean;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
}

const Dropdown: FC<PropsType> = ({
  containerRef,
  open,
  search,
  setSearch,
  ...rest
}) => {
  const { toTop } = usePosition(containerRef);

  return (
    <div
      className={classNames(
        "absolute left-0 right-0 min-w-44 w-min max-w-80 border border-gray bg-white rounded-base z-[22] overflow-hidden",
        toTop
          ? "bottom-full shadow-[rgb(213,213,213,05)_0px_0px_10px_0px]"
          : "top-full shadow-[rgb(213,213,213,05)_0px_8px_10px_0px]",
        open ? "block" : "hidden"
      )}
    >
      <OptionSearch
        optionSearchValue={search}
        setOptionSearchValue={setSearch}
      />

      <Options {...rest} />
    </div>
  );
};

export default Dropdown;
