import React, { createElement, ForwardRefExoticComponent } from "react";
import { isNextApp, isReactApp } from "../../helpers/dom/app";

interface PropsType {
  children?: React.ReactNode;
  className?: string;
  href?: string;
}

type LinkType = ForwardRefExoticComponent<any>;

interface LinkWrapperType extends PropsType {
  LinkComponent: LinkType;
}

const LinkWrapper: React.FC<LinkWrapperType> = ({
  LinkComponent,
  children,
  className,
  href,
  ...restProps
}) => {
  const isNextLink = isNextApp();
  const isReactLink = isReactApp();

  const RenderLink = () => {
    try {
      const linkProps = isNextLink
        ? { href, ...restProps }
        : isReactLink
        ? { to: href, ...restProps }
        : {};

      return createElement(
        LinkComponent,
        {
          ...linkProps,
          className,
        },
        children
      );
    } catch (error) {
      console.error(error);
      return <span>Link Error </span>;
    }
  };

  return RenderLink();
};

export type { LinkType };

export default LinkWrapper;
