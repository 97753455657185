import { FC, useState, useEffect, useRef } from "react";
import { propsType } from "./types";
import { Container, TooltipHolder, TextHolder } from "./styles";
import classNames from "classnames";
import useOutsideAlert from "../../utils/hooks/useOutsideAlert";

const Tooltip: FC<propsType> = ({
  children,
  data,
  direction,
  delay,
  onHold,
  hide,
  padding,
  bg,
  bold,
  capitalize,
  className,
  ...props
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  let timeout: NodeJS.Timeout;

  const [active, setActive] = useState(onHold);

  const showTip = () => {
    if (!hide && !onHold) {
      timeout = setTimeout(() => {
        setActive(true);
      }, delay || 1);
    }
  };

  const hideTip = () => {
    if (!hide && !onHold) {
      clearInterval(timeout);
      setActive(false);
    }
  };

  useEffect(() => {
    setActive(onHold);

    return () => {
      setActive(false);
    };
  }, [onHold]);

  useOutsideAlert(elementRef, () => setActive(false));

  return (
    <Container
      ref={elementRef}
      key={`its${onHold}`}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      aria-label="Tooltip"
      className={classNames("tooltip", className)}
    >
      {children}

      {Boolean(data) && (
        <>
          {!hide && active && (
            <TooltipHolder
              className={classNames("tooltip_box", direction || "top")}
              $padding={padding}
              $bg={bg}
              {...props}
            >
              {typeof data === "string" ? (
                <TextHolder $bold={bold} $capitalize={capitalize}>
                  {data}
                </TextHolder>
              ) : (
                data || ""
              )}
            </TooltipHolder>
          )}
        </>
      )}
    </Container>
  );
};

export default Tooltip;
