import { css } from "styled-components";
import * as Constant from "./constants";

//TODO
//change box shadow colors

export const placeholders = {
  makeCenter: css<any>`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  flexCenter: css<any>`
    display: flex;
    align-items: center;
  `,
  flex: css<any>`
    display: flex;
  `,
  successType: css<any>`
    background-color: ${({ theme: { color } }) => color.green};
    color: ${({ theme: { color } }) => color.white};
  `,
  errorType: css<any>`
    background-color: ${({ theme: { color } }) => color.red};
    color: ${({ theme: { color } }) => color.white};
  `,
  button: css<any>`
    background-color: ${({ $main, $dark, theme: { color } }) =>
      $main ? color.main : $dark ? color.sub : color.white};

    color: ${({ $main, $dark, theme: { color } }) =>
      $main ? color.white : $dark ? color.white : color.font};
    border-radius: ${({ round, theme: { element } }) =>
      round ? "100px" : element.radius};
    padding: 0.4rem 1rem;
    font-size: ${({ theme: { font } }) => font.sub};
    font-weight: 600;
    border: 1px solid
      ${({ $main, theme: { color } }) => ($main ? color.main : color.sub)};
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    ${({ theme: { placeholders } }) => placeholders.inputHeight};

    ${({ validation, success, theme: { placeholders } }) =>
      validation && success && placeholders.successType};
    ${({ validation, error, theme: { placeholders } }) =>
      validation && error && placeholders.errorType};

    ${({ $loading, theme: { placeholders } }) =>
      $loading && placeholders.flexCenter};

    &:hover {
      background-color: ${({ theme: { color } }) => color.white};
      color: ${({ theme: { color } }) => color.font};
      box-shadow: rgba(0, 0, 0, 0.2) 0 0 4px;

      ${({ validation, success, theme: { placeholders } }) =>
        validation && success && placeholders.successType};
      ${({ validation, error, theme: { placeholders } }) =>
        validation && error && placeholders.errorType};
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: ${({ theme: { color } }) => color.grey};
      color: ${({ theme: { color } }) => color.white};
      opacity: 0.5;
    }
  `,
  inputHeight: css<any>`
    height: ${Constant.inputHeight};
  `,
  boxShadow: css<any>`
    box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 4px,
      rgb(65, 71, 80) 0px 1px 0px inset !important;
  `,
  gradientBg: css<any>`
    background-image: ${({ theme: { color } }) =>
      `linear-gradient(to right, ${color.main}, ${color.sub})`};
  `,
  sectionMargin: css<any>`
    margin-top: 70px;
    margin-bottom: 70px;
  `,
  sectionMarginTop: css<any>`
    margin-top: 70px;
  `,
  sectionMarginBottom: css<any>`
    margin-bottom: 70px;
  `,
  sectionPadding: css<any>`
    padding-top: 70px;
    padding-bottom: 70px;
  `,
  sectionPaddingTop: css<any>`
    padding-top: 70px;
  `,
  sectionPaddingBottom: css<any>`
    padding-bottom: 70px;
  `,
  sectionBoxPadding: css<any>`
    padding: 70px 80px;
  `,
  bottomBorder: css<any>`
    position: relative;
    &::after {
      content: "";
      width: 0;
      height: 2px;
      transition: all 0.3s ease-in-out;
      background-color: ${({ theme: { color } }) => color.main};
      border-radius: ${({ theme: { element } }) => element.radius};
      position: absolute;
      bottom: -8px;
      left: 0;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  `,
  sectionHeading: css<any>`
    font-size: 2.875rem;
    font-weight: 800;
    max-width: 58%;
  `,
  pageCutHeader: css<any>`
    height: ${`calc(100vh - ${Constant.headerHeight})`};
  `,
  pageHeight: css<any>`
    height: ${`calc(100vh - ${Constant.headerFooterHeight})`};
  `,
  textNowrap: css<any>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  `,
  theme: {
    card: css<any>`
      background-color: ${({ theme: { color } }) => color.white};
      padding: 1.5rem;
      border-radius: ${({ theme: { element } }) => element.radius};
    `,
  },
};
