import React from "react";
import Dropdown from "../../../../../../ui/dropdown";
import { Bell } from "react-feather";

import Badge from "../badge";
import NotificationList from "./list";

import * as Styles from "./styles";

const Notification = () => {
  return (
    <div className="relative" title="Notification" aria-label="Notification">
      <Dropdown inlineAlign="left" content={<NotificationList />}>
        <div className="relative">
          <Styles.IconWrapper>
            <Badge />
            <Bell />
          </Styles.IconWrapper>
        </div>
      </Dropdown>
    </div>
  );
};

export default Notification;
