//TODO
// business change dropdown

const BusinessDropdown = () => {
  return (
    <div className="p-5 w-72 max-w-72 max-h-80 overflow-auto">
      BusinessDropdown
    </div>
  );
};

export default BusinessDropdown;
