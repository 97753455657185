import {
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  Ref,
} from "react";
import classNames from "classnames";
import Styles from "./styles.module.css";

export interface InputBoxType extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

const InputBox: ForwardRefRenderFunction<HTMLInputElement, InputBoxType> = (
  { className, ...rest },
  ref: Ref<HTMLInputElement>
) => {
  return (
    <input
      ref={ref}
      area-label="Input Box"
      className={classNames("input_box", Styles.input_box, className)}
      {...rest}
    />
  );
};

export default forwardRef(InputBox);
