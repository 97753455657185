import { Grid } from "react-feather";
import Dropdown from "../../../../../../ui/dropdown";
import ServicesDropdown from "./dropdown";
import * as Styles from "./styles";

//TODO
// complete APps dropdown

const Services = () => {
  return (
    <Styles.Container title="Apps">
      <Dropdown inlineAlign="left" content={<ServicesDropdown />}>
        <Styles.Wrapper>
          <Grid />
          {/* <Styles.TextHolder>Apps</Styles.TextHolder> */}
        </Styles.Wrapper>
      </Dropdown>
    </Styles.Container>
  );
};

export default Services;
