import Tooltip from "../../../tooltip";
import classNames from "classnames";
import {
  forwardRef,
  ForwardRefRenderFunction,
  MouseEvent,
  ReactNode,
  Ref,
} from "react";
import { Eye, EyeOff, Key, Search, XCircle } from "react-feather";

export interface InputIconType {
  icon?: {
    show?: boolean;
    src?: string | ReactNode;
    className?: string;
    name?: string;
    tooltip?: {
      show?: boolean;
      name?: string;
      direction?: string;
    };
    onClick?: (d?: any) => void;
  };
  type?: string;
  focused?: boolean;
  passShow?: boolean;
  passwordViewToggle?: boolean;
  value?: boolean;
  prefix?: boolean;
  suffix?: boolean;
}

const InputIcon: ForwardRefRenderFunction<HTMLButtonElement, InputIconType> = (
  { icon, type, focused, passShow, passwordViewToggle, value, prefix, suffix },
  ref: Ref<HTMLButtonElement>
) => {
  const handleClick = (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLImageElement>
  ) => {
    try {
      e?.stopPropagation();
      if (icon?.onClick) {
        icon?.onClick();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isPassShowToggle = type === "password" && suffix && passwordViewToggle;
  const passToggleName = passShow ? "Hide" : "Show";

  return (
    <button
      ref={ref}
      area-label="Input icon"
      className={classNames(
        "input_icon max-w-max h-auto cursor-pointer flex items-center justify-center border-0 outline-0 bg-transparent",
        suffix ? "mr-1.5 ml-0.1" : "ml-1.5 mr-0.1",
        icon?.className
      )}
      onClick={(e) => handleClick(e)}
    >
      <Tooltip
        hide={isPassShowToggle ? false : !icon?.tooltip || !icon?.tooltip?.show}
        data={isPassShowToggle ? passToggleName : icon?.tooltip?.name}
        direction={icon?.tooltip?.direction ?? "left"}
        className={classNames(
          "[&>svg]:w-4 [&>svg]:h-4 [&>svg]:transition-colors hover:[&>svg]:!text-main",
          focused ? "[&>svg]:!text-main" : "[&>svg]:text-grey"
        )}
      >
        {typeof icon?.src === "string" ? (
          <img
            src={icon?.src ? icon?.src : ""}
            alt={icon?.name ?? "input-icon"}
            className="w-3.5 h-auto"
          />
        ) : (
          <>
            {type === "password" &&
            !Boolean(icon?.src) &&
            (prefix || suffix) ? (
              <>
                {suffix && passwordViewToggle ? (
                  passShow ? (
                    <Eye size={16} />
                  ) : (
                    <EyeOff size={16} />
                  )
                ) : (
                  prefix && <Key />
                )}
              </>
            ) : type === "search" && !Boolean(icon?.src) ? (
              <>{suffix && value ? <XCircle /> : prefix && <Search />}</>
            ) : (
              icon?.src
            )}
          </>
        )}
      </Tooltip>
    </button>
  );
};

export default forwardRef(InputIcon);
