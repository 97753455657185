import Logo from "../../ui/logo";
import { LinkType } from "../../utils/hooks/Link";
import PageDimensions from "../../utils/hooks/usePageDimensions";
import AppContainer from "../app-container";
import ActionArea from "./actions";
import BurgerIcon from "./burger";
import * as Data from "./data";
import MobileMenu from "./mobile";
import AllSearch from "./search";
import * as Styles from "./styles";

const AppHeader = ({
  LinkComponent,
  pathname,
}: {
  LinkComponent: LinkType;
  pathname?: string;
}) => {
  const hideHeader =
    (pathname && Data?.hideOnPaths?.includes(pathname)) ||
    Data?.hideOnPaths?.some((item) => pathname?.indexOf(item) !== -1);

  const { pageWidth } = PageDimensions();

  if (hideHeader) {
    return null;
  }

  return (
    <Styles.Container
      id="global-header"
      aria-label="App Header"
      $hide={hideHeader}
    >
      <AppContainer $fluid>
        <Styles.Wrapper aria-label="App Header wrapper">
          <Styles.LeftWrapper>
            <Styles.Branding aria-label="Branding">
              <Logo iconOnly LinkComponent={LinkComponent} />
            </Styles.Branding>
          </Styles.LeftWrapper>
          <AllSearch />
          <Styles.RightWrapper aria-label="Actions">
            <ActionArea />
            <BurgerIcon />
          </Styles.RightWrapper>
        </Styles.Wrapper>
      </AppContainer>
      {pageWidth?.desktop_down && <MobileMenu />}
    </Styles.Container>
  );
};

export default AppHeader;
