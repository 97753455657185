import { FC } from "react";
import classNames from "classnames";
import Styles from "./styles.module.css";

export interface InputLabelType {
  name?: string;
  className?: string;
  dynamic?: boolean;
  focused?: boolean;
  focused_className?: string;
}

const InputLabel: FC<InputLabelType> = ({
  name,
  dynamic,
  focused,
  className,
  focused_className,
}) => {
  return (
    <label
      className={classNames(
        "input_label",
        Styles.input_label,
        className,
        dynamic && Styles.input_label_dynamic,
        dynamic && focused && Styles.input_label_dynamic_focused,
        dynamic && focused && focused_className
      )}
    >
      {name}
    </label>
  );
};

export default InputLabel;
