import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
// import useAuth from "@/pages/Auth/@context/useAuth";
import ConsoleLayout from "@/toolkit/layouts/console";
import { menuData } from "../data/menu";

const PrivateRoute = () => {
  // const { auth, loading } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const loading = false;
  const isAuthenticated = true;

  const navigateToSSO = () => {
    try {
      if (window) {
        const currentUrl = window.location.href;
        const SSOurl = `${
          process.env.REACT_APP_SSO_URL
        }?redirectUrl=${encodeURIComponent(currentUrl)}`;
        window.location.href = SSOurl ?? "/auth";
      } else {
        navigate("/auth");
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  if (!loading && !isAuthenticated) {
    return <button onClick={() => navigateToSSO()}>Login now</button>;
  }

  return (
    <ConsoleLayout
      menuData={menuData}
      LinkComponent={Link}
      pathname={pathname}
      onSidebarNavigate={(path, options) =>
        navigate(path ?? "/", { ...(options ?? {}) })
      }
    >
      <div className="h-page w-full overflow-auto">
        <Outlet />
      </div>
    </ConsoleLayout>
  );
};

export default PrivateRoute;
