import classNames from "classnames";
import { forwardRef, Ref } from "react";
import { ArrowRight } from "react-feather";
import { AllowedSize, AllowedVariantsType, ButtonType } from "./types";
import Loader from "../loader";

interface VariantsType {
  variant?: AllowedVariantsType;
  size?: AllowedSize;
  effect?: boolean;
}

const buttonVariants = ({ variant, size, effect }: VariantsType): string => {
  return classNames(
    "cursor-pointer text-sb font-medium rounded-base py-1.5 px-4 h-input transition-all border inline-flex items-center justify-center whitespace-nowrap hover:shadow-lg focus:outline-0 disabled:bg-grey disabled:text-white disabled:opacity-50",
    {
      "bg-main text-white border-main hover:bg-white hover:text-font": !variant,
      "min-w-max cursor-pointer inline-flex items-center justify-center rounded-base outline-0 bg-inherit border border-transparent box-border transition-all align-baseline no-underline focus:outline-0 disabled:bg-grey disabled:text-white disabled:opacity-50":
        variant === "action",
      "bg-green text-white border-green": variant === "success",
      "bg-red text-white border-red": variant === "error",
      "bg-yellow text-font border-yellow": variant === "warning",
      "bg-white text-font border-font": variant === "outline",
      "bg-slate-100 text-font border-slate-400": variant === "ghost",
      "bg-sub text-white border-font hover:bg-white hover:text-font":
        variant === "dark",
      "hover:shadow hover:bg-slate-200": effect,
      "w-full": size === "full",
      "w-1/2": size === "half",
    }
  );
};

const Button = forwardRef<HTMLButtonElement, ButtonType>(
  (
    {
      loading,
      variant,
      size,
      className,
      children,
      rightArrow,
      effect,
      ...rest
    },
    ref: Ref<HTMLButtonElement>
  ) => {
    return (
      <button
        ref={ref}
        className={classNames(
          "button group",
          buttonVariants({ variant, size, effect }),
          className
        )}
        {...rest}
        style={{ textSizeAdjust: "auto", ...(rest?.style ?? {}) }}
      >
        {children}

        {rightArrow && (
          <div className="arrow_right ml-0.5 group-hover:transition-[transform] duration-300 group-hover:translate-x-1/2">
            <ArrowRight />
          </div>
        )}

        {loading && (
          <div className="loader_wrapper ml-2">
            <Loader dark small />
          </div>
        )}
      </button>
    );
  }
);

export { buttonVariants };

export default Button;
