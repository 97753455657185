import PageDimensions from "../../../styles/usePageDimensions";
// import * as Actions from "../../../../pages/layouts/@redux/actions";

const MobileMenu = () => {
  const { pageWidth } = PageDimensions();
  // const dispatch = useDispatch();

  const auth = true;

  let startX;

  const handleTouchStart = (e) => {
    try {
      startX = e.touches[0].clientX;
    } catch (err) {
      console.error(err);
    }
  };

  const handleTouchMove = (e) => {
    try {
      const currentX = e.touches[0].clientX;
      const diffX = startX - currentX;

      if (diffX > 90) {
        // dispatch(Actions.getSidebarClosed());
      }
    } catch (err) {
      console.error(err);
    }
  };

  // return (
  //   <SideDrawer onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
  //     <Styles.Container>
  //       {auth && pageWidth?.mobile_down && <MobileProfile />}
  //       {!auth && <MobileGuestMenu />}
  //     </Styles.Container>
  //   </SideDrawer>
  // );

  return <>MobileMenu</>;
};

export default MobileMenu;
