import {
  ArrowRight,
  Box,
  Download,
  Feather,
  Film,
  Grid,
  HardDrive,
  Home,
  Layout,
  Tool,
} from "react-feather";

export const menuData = [
  {
    allow: ["/*"],
    title: "Dashboard",
    path: "#dashboard",
    data: [
      {
        title: "",
        menu: [
          {
            name: "Dashboard",
            path: "/",
            icon: Home,
            description: "",
          },
        ],
      },
    ],
  },
  {
    allow: ["/", "/installation"],
    title: "Documentations",
    path: "#documentation",
    data: [
      {
        title: "",
        menu: [
          {
            name: "Installation",
            path: "/installation",
            icon: Download,
          },
          {
            name: "UI Components",
            path: "/ui",
            icon: Box,
          },
          {
            name: "Utils",
            path: "/utils",
            icon: Tool,
          },
          {
            name: "Layouts",
            path: "/layouts",
            icon: Layout,
          },
          {
            name: "Styles",
            path: "/styles",
            icon: Feather,
          },
          {
            name: "Common",
            path: "/common",
            icon: Grid,
          },
          {
            name: "Assets",
            path: "/assets",
            icon: Film,
          },
          {
            name: "All Toolkit",
            path: "/all",
            icon: HardDrive,
          },
        ],
      },
    ],
  },
  {
    allow: ["/ui/*"],
    title: "Ui",
    path: "#ui",
    data: [
      {
        title: "",
        menu: [
          {
            name: "UI Components",
            path: "/ui",
            icon: Box,
          },
          {
            id: "alerts",
            name: "Alert",
            path: "/ui/alerts",
            icon: ArrowRight,
          },
          {
            id: "button",
            name: "Button",
            path: "/ui/button",
            icon: ArrowRight,
          },
        ],
      },
    ],
  },
  {
    allow: ["/utils/*"],
    title: "Utils",
    path: "#utils",
    data: [
      {
        title: "",
        menu: [
          {
            name: "Utils",
            path: "/utils",
            icon: Tool,
          },
          {
            id: "helper",
            name: "Helper",
            path: "/utils/helper",
            icon: ArrowRight,
          },
          {
            id: "hooks",
            name: "Hooks",
            path: "/utils/hooks",
            icon: ArrowRight,
          },
          {
            id: "validators",
            name: "Validators",
            path: "/utils/validators",
            icon: ArrowRight,
          },
        ],
      },
    ],
  },
  {
    allow: ["/assets/*"],
    title: "Assets",
    path: "#assets",
    data: [
      {
        title: "",
        menu: [
          {
            name: "Assets",
            path: "/assets",
            icon: Film,
          },
          {
            name: "Branding",
            path: "/assets/branding",
            icon: ArrowRight,
          },
          {
            id: "placeholder",
            name: "placeholder",
            path: "/assets/placeholder",
            icon: ArrowRight,
          },
        ],
      },
    ],
  },
];
