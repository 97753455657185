import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;

  width: 100%;
  max-width: 40vw;
  min-height: min-content;
  height: auto;
  max-height: calc(100vh - 6rem);

  padding: 1rem 1.5rem;
  backdrop-filter: blur(4px);

  background-color: ${({ theme: { color } }) => color.sub};
  color: ${({ theme: { color } }) => color.white};

  border-radius: ${({ theme: { element } }) =>
    `0 0 ${element.radius} ${element.radius}`};

  ${({ theme: { placeholders } }) => placeholders.boxShadow};
`;

export const Wrapper = styled.div`
  ${({ theme: { placeholders } }) => placeholders.flex};
`;
