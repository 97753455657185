"use client";

import classNames from "classnames";
import { useEffect, useState } from "react";
import Loader from "../../loader";

const CreateSvg = ({ src, className }: { src: string; className?: string }) => {
  const [svgContent, setSvgContent] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const fetchSvg = async () => {
      try {
        const response = await fetch(src);
        if (!response.ok) {
          throw new Error(`Failed to load SVG: ${response.statusText}`);
        }
        const svgText = await response.text();
        setSvgContent(svgText);
      } catch (err) {
        console.error(err);
        setError(true);
      }
    };

    fetchSvg();
  }, [src]);

  if (error) {
    return <span>Invalid SVG</span>;
  }

  if (!svgContent) {
    return <Loader />;
  }

  return (
    <span
      className={classNames(
        "w-full h-full [&>svg]:w-full [&>svg]:h-full",
        className
      )}
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  );
};

export default CreateSvg;
