import { FC } from "react";
import LinkWrapper, { type LinkType } from "../../utils/hooks/Link";
import classNames from "classnames";
import logo from "../../assets/branding/logo.svg";
import symbol from "../../assets/branding/symbol.svg";
import CreateSvg from "../icon/svg";

export interface LogoType {
  href?: string;
  iconOnly?: boolean;
  LinkComponent: LinkType;
}

const LegalyardLogo: FC<LogoType> = ({ href, iconOnly, LinkComponent }) => {
  return (
    <div
      title="Legalyard"
      aria-label="Logo"
      className={classNames(
        "logo p-0.5 w-full max-w-max [&>svg]:fill-inherit",
        iconOnly ? "aspect-square" : "aspect-auto"
      )}
    >
      <LinkWrapper
        LinkComponent={LinkComponent}
        href={href ? href : "/"}
        className="text-inherit w-full h-full flex items-center justify-center max-w-max no-underline"
      >
        {iconOnly ? <CreateSvg src={symbol} /> : <CreateSvg src={logo} />}
      </LinkWrapper>
    </div>
  );
};

export default LegalyardLogo;
