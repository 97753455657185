import styled from "styled-components";
import { StyledType } from "../../../types";

export const Container = styled.div`
  position: relative;
`;

export const Wrapper = styled.div`
  cursor: pointer;
  overflow: hidden;
  padding: 0.8rem 0 0.8rem 1rem;

  ${({ theme: { placeholders } }) => placeholders.flexCenter};
  column-gap: 1rem;
  max-width: 250px;
`;

export const TextWrapper = styled.div`
  flex: 1;
  ${({ theme: { placeholders } }) => placeholders.flex};
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.1rem;
  max-width: 130px;
`;

export const TextHolder = styled.span<StyledType>`
  ${({ theme: { placeholders } }) => placeholders.textNowrap};
  font-weight: ${({ $bold }) => ($bold ? 600 : 400)};
  font-size: ${({ $sub, theme: { font } }) => ($sub ? font.small : font.sub)};
  color: ${({ $sub, theme: { color } }) =>
    $sub ? `${color.grey}70` : color.white};
  line-height: 1.048;
`;
