import styled from "styled-components";
import { StyledType } from "../../../../types";

export const Container = styled.div`
  background-color: ${({ theme: { color } }) => color.white};
  box-shadow: 0 5px 10px ${({ theme: { color } }) => `${color.main}20`};
  width: 250px;
  max-width: 250px;

  border-bottom-right-radius: ${({ theme: { element } }) => element.radius};
  border-bottom-left-radius: ${({ theme: { element } }) => element.radius};
`;

export const UserTitleHolder = styled.span`
  display: block;
  padding: 12px 12px 0;
  text-transform: capitalize;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Section = styled.div`
  padding: 10px 0 0;

  &:first-of-type {
    padding-top: 15px;
  }
  &:last-of-type {
    padding-bottom: 20px;
  }
`;

export const TitleWrapper = styled.div`
  color: ${({ theme: { color } }) => color.white};
  max-width: calc(100% - 25px);
  margin-bottom: 10px;
  margin-inline: auto;
  height: 15px;
  position: relative;

  &::before {
    content: "";
    background-color: ${({ theme: { color } }) => `${color.dark}50`};
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
  }
`;

export const TitleHolder = styled.span`
  text-transform: capitalize;
  display: inline-block;
  background-color: #fff;
  font-size: ${({ theme: { font } }) => font.sub};
  font-weight: 400;
  line-height: 15px;
  color: ${({ theme: { color } }) => `${color.dark}50`};
  padding-right: 12px;
  position: absolute;
  left: 0;
  top: 0;
`;

export const MenuList = styled.div`
  ${({ theme: { placeholders } }) => placeholders.flex};
  align-items: flex-start;
  flex-direction: column;
  row-gap: 0.01rem;
`;

export const MenuItem = styled.div<StyledType>`
  width: 100%;

  .action-holder {
    display: block;
    width: 100%;

    &:not(button) {
      padding: 6px 20px;
    }
  }

  button {
    &.action-holder {
      margin: 0 1.25rem;
      width: -webkit-fill-available;
    }
  }

  background-color: ${({ $active, theme: { color } }) =>
    $active ? `${color.sub}05` : "transparent"};

  &:hover {
    background-color: ${({ theme: { color } }) => `${color.sub}05`};

    a,
    span,
    div {
      color: ${({ theme: { color } }) => color.main};
    }
  }
`;

export const TipWrapper = styled.div`
  ${({ theme: { placeholders } }) => placeholders.flexCenter};
  font-size: 11px;
`;

export const TipLinkHolder = styled.a`
  color: ${({ theme: { color } }) => color.sub};
  font-weight: 600;
  margin-left: 5px;
  letter-spacing: 0.05em;
`;

export const TipHolder = styled.span<StyledType>`
  color: ${({ theme: { color } }) => `${color.dark}99`};
`;

export const TextHolder = styled.span<StyledType>`
  font-size: ${({ theme: { font } }) => font.main};

  color: ${({ $active, theme: { color } }) =>
    $active ? color.main : color.dark};
`;

export const EventWrapper = styled.div`
  cursor: pointer;
  user-select: none;
`;
