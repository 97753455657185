import classNames from "classnames";
import { FC } from "react";
import { TableLoaderType } from "../../types";

const TableLoader: FC<TableLoaderType> = ({
  column = 5,
  row = 4,
  className,
}) => {
  return (
    <div
      className={classNames(
        "ui_loader_table w-full flex flex-col gap-4 p-3",
        className
      )}
    >
      {[...Array(+row).keys()]?.map((col, index) => {
        return (
          <div
            key={index + col}
            className="w-full flex flex-row items-center gap-4"
          >
            {[...Array(+column).keys()]?.map((row, rowIndex) => {
              return (
                <div
                  key={rowIndex + row}
                  className="w-full flex flex-col items-center gap-4"
                >
                  <div
                    className={classNames(
                      "inline-block min-h-9 w-full relative overflow-hidden bg-[#dddbdd] rounded-base shadow mx-auto",
                      "after:animate-shimmer after:[animation-duration:_2s] after:[animation-iteration-count:_infinite]",
                      "after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:-translate-x-full",
                      "after:bg-gradient-to-r after:from-transparent after:via-[rgba(255,255,255,0.75)] after:to-transparent"
                    )}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default TableLoader;
