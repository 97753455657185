import Notification from "./notification";
import Message from "./message";
import Business from "./business";
import * as Styles from "./styles";
import Services from "./services";

const Icons = () => {
  return (
    <Styles.Container>
      <Services />
      <Notification />
      <Message />
      <Business />
    </Styles.Container>
  );
};

export default Icons;
