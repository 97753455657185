import { FC } from "react";
import { footerHeight, headerHeight } from "../../styles/constants";
import Footer from "../footer";
import AppHeader from "../header";
import DashboardSidebar from "../sidebar";
import { ConsoleLayoutType } from "./types";

const ConsoleLayout: FC<ConsoleLayoutType> = ({
  children,
  menuData,
  LinkComponent,
  pathname,
  onSidebarNavigate,
}) => {
  return (
    <div
      aria-label="Dashboard layout"
      style={{ padding: `${headerHeight} 0 ${footerHeight}` }}
      className="h-full"
    >
      <AppHeader LinkComponent={LinkComponent} pathname={pathname} />

      <main className="relative flex items-start">
        <DashboardSidebar
          data={menuData}
          pathname={pathname}
          onNavigate={onSidebarNavigate}
        />

        <div className="flex-1 w-full h-page" aria-label="Page">
          {children}
        </div>
      </main>
      <Footer pathname={pathname} />
    </div>
  );
};

export default ConsoleLayout;
