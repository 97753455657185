import { countryCodes } from "../../../../../common/locale/country-code";
import { Dispatch, FC, SetStateAction } from "react";
import OptionItem from "./item";

interface PropsType {
  handleSelect?: (d: any) => void;
  resultCount?: number;
  setResultCount?: Dispatch<SetStateAction<number>>;
  renderList?: any[];
  selected?: string;
}

const Options: FC<PropsType> = ({
  handleSelect,
  resultCount,
  setResultCount,
  renderList,
  selected,
}) => {
  const handleScroll = () => {
    if (resultCount && renderList && resultCount < renderList?.length) {
      if (setResultCount) {
        setResultCount(resultCount + 10);
      }
    }
  };

  const getSelected = countryCodes.find((item) => item.dialCode === selected);

  const SelectCountry = (val: string) => {
    if (handleSelect) {
      handleSelect(val);
    }
  };

  return (
    <div
      className="h-fit max-h-52 overscroll-x-none overflow-y-auto"
      onScroll={handleScroll}
    >
      <OptionItem
        selected={selected === "+91"}
        onClick={() => SelectCountry("+91")}
        option={{
          flag: "https://cdn.kcak11.com/CountryFlags/countries/in.svg",
          name: "India",
          dialCode: "+91",
        }}
      />
      {getSelected && getSelected?.isoCode !== "IN" && (
        <OptionItem
          selected={selected === getSelected?.dialCode}
          onClick={() => SelectCountry(getSelected?.dialCode)}
          option={getSelected}
        />
      )}

      {renderList &&
        renderList?.length > 0 &&
        renderList.slice(0, resultCount).map((option, index) => {
          return (
            <OptionItem
              key={index + String(option?.isoCode)}
              selected={selected === option?.dialCode}
              onClick={() => SelectCountry(String(option?.dialCode))}
              option={option}
            />
          );
        })}
    </div>
  );
};

export default Options;
