import * as Styles from './styles'

//TODO
// Complete the dropdown

const SearchDropdown = () => {
  return (
    <Styles.Container>SearchDropdown</Styles.Container>
  )
}

export default SearchDropdown