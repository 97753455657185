import { lazy } from "react";
import { RouteType } from "./types";

const AppRoutes: RouteType[] = [
  {
    id: "404",
    name: "404",
    path: "*",
    component: lazy(() => import("@/directory/pages/404")),
    type: "open",
    props: {},
  },
];

export default AppRoutes;
